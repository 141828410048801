.textColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div {
        max-inline-size: 400px;
    }

    a {
        align-self: flex-start;
    }
}

.sceneColumn {
    max-inline-size: 590px;
}
