.playground_container__GjOkW {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-32);
    padding-block: var(--space-96);
}

.playground_tabBar__a8oVo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    inline-size: 100%;
    margin-block-start: var(--space-4);
    border-bottom: 1px solid var(--elevated-border);
}

.playground_selectField__A7TQV {
    margin-block-end: var(--space-24);
}

.playground_selectField__A7TQV > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

.playground_tab__sWpT7 {
    padding: var(--space-8) var(--space-12);
    text-decoration: none;
    margin-bottom: -1px;
    text-align: center;
}

.playground_tab__sWpT7 p {
        color: var(--display-onlight-tertiary);
    }

.playground_tab__sWpT7 a {
        text-decoration: none;
    }

.playground_selected__MO828 {
    border-bottom: 1px solid var(--display-onlight-primary);
}

.playground_selected__MO828 p {
        color: var(--display-onlight-primary);
    }

.playground_StoryContainer__0bkja {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-12);
    position: relative;

    inline-size: 100%;

    padding: var(--space-24);
    padding-block-start: var(--space-32);
    margin-block-end: var(--space-24);

    background-color: var(--composition-background-neutral);
    border: 1px dashed var(--elevated-border);
    border-radius: var(--border-radius-xl);
    background-color: #fff;
}

.playground_StoryContainer__0bkja .playground_badge__avPOI {
        position: absolute;
        top: -13px;
        left: 24px;
    }

.playground_StoryContainer__0bkja .playground_badge__avPOI span {
            font-family: monospace;
            font-weight: 600;
        }

.playground_story__2lJEI {
    display: flex;
    flex-direction: column;
    gap: var(--space-12);
    padding: var(--space-8) var(--space-12);
    border: 1px dashed var(--elevated-border);
    border-radius: var(--border-radius-base);
    background-color: #fff;
    inline-size: 100%;
}

.playground_story__2lJEI .playground_name__4TLs0 {
        align-self: flex-start;
        color: var(--display-onlight-tertiary);
    }

.playground_story__2lJEI .playground_components__ydD3f {
        display: flex;
        gap: var(--space-12);
    }

.playground_story__2lJEI .playground_components__ydD3f.playground_vertical__1PQeo {
            flex-direction: column;
        }

.playground_boardView__3y2qd {
    gap: 12px;
    display: flex;
    align-items: flex-start;
    gap: var(--space-12);
    flex: 1 1;
}

.playground_boardView__3y2qd > * {
        flex: 1 1;
    }

.playground_column__IxZdx {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1 0;
}

.playground_scenes__KcYmS > div > h2 {
        margin-block-end: var(--space-16);
    }

.playground_scenes__KcYmS > div > h2:not(:first-child) {
            margin-block-start: var(--space-64);
        }

.playground_scenes__KcYmS > div > ul {
        margin-block-end: var(--space-32);

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--space-24);
        gap: var(--space-24);
    }

.playground_scenes__KcYmS > div > ul > li {
            margin-block-start: var(--space-16);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

.playground_scenes__KcYmS > div > ul > li h3 {
                margin-block-end: var(--space-16);
            }

.playground_scenes__KcYmS > div > ul.playground_views__p4bfv {
            grid-template-columns: 1fr;
        }

.playground_themeContainer__py1CP {
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: var(--composition-background-neutral);
}

.product-ui-box-shadow_boxShadow__xRSUA {
    box-shadow: var(--box-shadow, var(--shadow-productui-app-window));
    mix-blend-mode: multiply;
    border-radius: var(--border-radius-xl);
    pointer-events: none;

    position: absolute;
    inset: 0;
}

.product-ui-box-shadow_red__pPueo {
    --box-shadow: var(--shadow-elevated-red);
}

.product-ui-box-shadow_green__W2nZm {
    --box-shadow: var(--shadow-elevated-green);
}

.product-ui-box-shadow_yellow__tK9vS {
    --box-shadow: var(--shadow-elevated-yellow);
}

.product-ui-box-shadow_seaSalt__4kyQi {
    --box-shadow: var(--shadow-elevated-sea-salt);
}

.product-ui-overlay_overlay__BZ_4Q {
    position: relative;
}

    .product-ui-overlay_overlay__BZ_4Q::after {
        content: "";
        position: absolute;
        inset: 0;
        -webkit-backdrop-filter: blur(4px);
                backdrop-filter: blur(4px);
        background-color: var(--overlay-background-idle);
    }

.product-ui-app-window_windowWrapper__kxUUW {
    position: relative;
    inline-size: 100%;
    block-size: 100%;
}

.product-ui-app-window_windowFrame__Eb7O1 {
    --sidebar-inline-size: 15.625rem; /* 250px */
    --topbar-block-size: 2.625rem; /* 42px */

    inline-size: var(--window-inline-size, 100%);
    block-size: var(--window-block-size, 100%);
    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-base);

    position: relative;
    display: grid;
    grid-template-columns: [full-start sidebar-start] var(--sidebar-inline-size) [sidebar-end view-start] 1fr [view-end full-end];
    grid-template-rows: [full-start topbar-start] var(--topbar-block-size) [topbar-end view-start] 1fr [view-end full-end];
}

.product-ui-app-window_overlay__9mj0N {
    inline-size: var(--window-inline-size, 100%);
    block-size: var(--window-block-size, 100%);
}

.product-ui-app-window_overlay__9mj0N::after {
        border-radius: var(--border-radius-base);
    }

.product-ui-app-window_views__vakkV {
    display: grid;
    inline-size: 100%;
    block-size: 100%;
    border-radius: inherit;
    overflow: hidden;
    grid-column: view;
    grid-row: topbar-start / view-end;
}

.product-ui-app-window_views__vakkV > * {
        grid-area: 1 / 1;
    }

.product-ui-app-window_view__XOlB7 {
    inline-size: 100%;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.product-ui-app-window_topbar__Pmiig {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    grid-column: sidebar-start / view-end;
}

.product-ui-app-window_sidebar__aS56u {
    grid-column: sidebar;
    grid-row: topbar-start / view-end;
}

.product-ui-app-window_rightSidePanel__RO8Ex {
    grid-column: rightsidebar;
    grid-row: topbar-start / view-end;
}

.product-ui-app-window_modal__6rSL4 {
    border-radius: inherit;
    grid-area: full;
    display: grid;
    grid-template-columns:
        [full-start] var(--space-24) [modal-start] 1fr [modal-end] var(--space-24)
        [full-end];
    grid-template-rows:
        [full-start] var(--space-24) [modal-start] 1fr [modal-end] var(--space-24)
        [full-end];
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    background-color: var(--overlay-background-idle);
    z-index: var(--product-ui-z-index-popup);
}

.product-ui-app-window_modal__6rSL4 > * {
        grid-area: modal;
    }

.product-ui-app-window_hideSidebar__X70zr .product-ui-app-window_sidebar__aS56u {
        transform: translateX(calc(--sidebar-inline-size * -1));
    }

.product-ui-app-window_hideSidebar__X70zr .product-ui-app-window_views__vakkV {
        grid-column: sidebar-start / view-end;
        margin-inline: auto;
    }

.product-ui-icon-button_iconButton__c7n7w {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-4);
    border-radius: var(--border-radius-sm);
}

    .product-ui-icon-button_iconButton__c7n7w > svg {
        color: var(--display-onlight-tertiary);
        inline-size: var(--space-24);
        block-size: var(--space-24);
    }

    .product-ui-icon-button_iconButton__c7n7w.product-ui-icon-button_base__QXeDA {
        padding: 5px;
    }

    .product-ui-icon-button_iconButton__c7n7w.product-ui-icon-button_base__QXeDA > svg {
            inline-size: 18px;
            block-size: 18px;
        }

    .product-ui-icon-button_iconButton__c7n7w.product-ui-icon-button_hovered___zTOa {
        background-color: var(--actionable-quaternary-background-onhover);
    }

    .product-ui-icon-button_iconButton__c7n7w.product-ui-icon-button_hovered___zTOa > svg {
            color: var(--display-onlight-primary);
        }

    .product-ui-icon-button_iconButton__c7n7w.product-ui-icon-button_active__lQ656 {
        background-color: var(--productui-sidebar-actionable-quaternary-background-active);
    }

    .product-ui-icon-button_iconButton__c7n7w.product-ui-icon-button_active__lQ656 > svg {
            color: var(--display-onlight-primary);
        }

.product-ui-quick-add-button_quickAddWrapper__s9LGr {
    --product-ui-display-onlight-skeleton: var(--productui-display-skeleton-color);
    --left-right-gap: 6px;
    --list-item-background-hover: var(--productui-sidebar-actionable-quaternary-background-active);
}

    .product-ui-quick-add-button_quickAddWrapper__s9LGr p {
        color: var(--productui-primary-theme-color);
    }

.product-ui-quick-add-button_quickAddIcon__zoCu6 {
    color: var(--productui-primary-theme-color);
}

.product-ui-right-side-panel_rightSidePanel__cUjWf {
    inline-size: 250px;
    block-size: 100%;
    border-left: 1px solid var(--ui-border);
}

.product-ui-right-side-panel_header__gVH7H {
    display: flex;
    justify-content: space-between;
    align-items: center;
    block-size: 56px;
    border-bottom: 1px solid var(--ui-border);
    padding: var(--space-12);
}

.product-ui-right-side-panel_item__ABfnA {
    margin: 0 var(--space-12);
    border-bottom: 1px solid var(--ui-border);
}

.product-ui-scene-container_sceneContainer__HonPF {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    block-size: 100%;
    inline-size: 100%;
    border-radius: inherit;
}

    .product-ui-scene-container_sceneContainer__HonPF > * {
        grid-row: 1;
        grid-column: 1;
    }

.product-ui-scene-container_sceneLayer__CVTWQ {
    display: grid;
    inline-size: 100%;
    block-size: 100%;
    overflow: hidden;
    z-index: 1;
}

.product-ui-scene-container_sceneLayer__CVTWQ > * {
        grid-column: content;
        grid-row: content;
    }

.product-ui-scene-container_bottom__doAV1,
.product-ui-scene-container_bottomCenter__eSrAF {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end] var(
            --product-ui-frame-inset-base
        ) [full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
}

.product-ui-scene-container_bottomCenter__eSrAF {
    justify-items: center;
}

.product-ui-scene-container_bottomRight__CUnt_ {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
}

.product-ui-scene-container_bottomLeft__cxcWT {
    grid-template-columns: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-base
        ) [full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
    justify-content: end;
}

.product-ui-scene-container_bottomOverlay__rgWFC {
    grid-template-rows: [full-start] var(--product-ui-frame-inset-window-modal-open) [content-start] 1fr [content-end full-end];
    grid-template-columns: [full-start content-start] 1fr [content-end full-end];
    justify-content: center;
}

.product-ui-scene-container_modalOpenLeft___y17Q {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-window-modal-open) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
}

.product-ui-scene-container_modalOpenTopRight__nivHc {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-window-overlay-view-open) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-window-overlay-view-open
        ) [full-end];
    align-content: end;
}

.product-ui-scene-container_sceneContainer__HonPF:has(.product-ui-scene-container_modalOpenTopRight__nivHc) {
        align-content: end;
    }

.product-ui-scene-container_modalOpenBottomRight__odC1K {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-window-overlay-view-open) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-window-overlay-view-open) [content-start] 1fr [content-end full-end];
}

.product-ui-scene-container_modalOpenCenterLeft__iiOcs {
    grid-template-columns: [full-start content-start] 1fr [content-end] var(--space-128) [full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(--space-32) [full-end];
    align-content: end;
    justify-content: end;
}

.product-ui-scene-container_sceneContainer__HonPF:has(.product-ui-scene-container_modalOpenCenterLeft__iiOcs) {
        align-content: end;
    }

.product-ui-scene-container_modalOpenTopLeft__D6s8i {
    grid-template-columns: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-window-overlay-view-open
        ) [full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-window-overlay-view-open
        ) [full-end];
    align-content: end;
    justify-content: end;
}

.product-ui-scene-container_sceneContainer__HonPF:has(.product-ui-scene-container_modalOpenTopLeft__D6s8i) {
        align-content: end;
    }

.product-ui-scene-container_center__a_zGg {
    grid-template-columns:
        [full-start] var(--product-ui-frame-inset-overlay-view-base)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];
    grid-template-rows:
        [full-start] var(--product-ui-frame-inset-overlay-view-base)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];

    align-items: center;
}

.product-ui-scene-container_centerTop__Ji5H9 {
    grid-template-columns:
        [full-start] var(--product-ui-frame-inset-overlay-view-base)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];
    grid-template-rows:
        [full-start] var(--product-ui-frame-inset-overlay-view-top)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];

    align-items: flex-start;
}

.product-ui-scene-container_topRight__dIFkz {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-base
        ) [full-end];
    align-content: end;
}

.product-ui-view-header_header__YYpnf {
    display: grid;
}

.product-ui-view-header_top__MS72L {
    block-size: 56px;
    padding: 0 var(--space-12) 0 var(--space-8);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.product-ui-view-header_bottom__BfDNr {
    padding: var(--space-16) var(--space-48) var(--space-32);
    margin: 0 auto;
    inline-size: 100%;
    max-inline-size: var(--container-width-xs, 698px);
}

.product-ui-view-header_bottom__BfDNr.product-ui-view-header_fullWidth__JWLy4 {
        max-inline-size: 100%;
    }

.product-ui-view-header_bottom__BfDNr.product-ui-view-header_noBottomPadding__pndrC {
        padding-block-end: 0;
    }

.product-ui-view-header_bottom__BfDNr.product-ui-view-header_minPadding__MSuJ_ {
        padding-inline: var(--space-12);
    }

.product-ui-view-header_breadcrumbsWrapper__WY23Q {
    display: flex;
    align-items: center;
}

.product-ui-view-header_breadcrumbs__nVEHv {
    display: flex;
    align-items: center;
    padding-inline-start: var(--space-4);
}

.product-ui-view-header_breadcrumbsSeparator__PDxg8 {
    margin-inline: var(--space-4);
    color: var(--display-onlight-tertiary);
}

.product-ui-view-header_actions__t8nME {
    display: flex;
    gap: var(--space-12);
}

.product-ui-view-header_title__d5kIL {
    display: flex;
    gap: var(--space-12);
    align-items: center;
}

.product-ui-view-header_calendarControls__QNxNe {
    margin-block-start: var(--space-8);
    margin-block-end: var(--space-16);
    color: var(--display-onlight-secondary);
}

.product-ui-view-header_calendarControlsDefault__4A5Sd {
    display: flex;
    justify-content: flex-end;
    gap: var(--space-12);
}

.product-ui-view-header_calendarControlsSubtitle__pgKsA {
    display: flex;
    gap: 2px;
    margin-inline-end: auto;
    align-items: center;
}

.product-ui-view-header_todayViewSubtitle__WMrvR {
    display: flex;
    gap: var(--space-12);
    margin-block-start: var(--space-8);
}

.product-ui-view-header_todayViewItem__xwYlT {
    display: flex;
    gap: var(--space-4);
    align-items: center;
    color: var(--display-onlight-secondary);
}

.product-ui-view-header_previewBanner__EHC9n {
    display: flex;
    min-height: var(--tube-height-base);
    padding: 0px var(--space-12);
    align-items: center;
    gap: var(--space-12);
    background-color: var(--productui-themed-box-background);
    border-radius: var(--border-radius-lg);
}

.product-ui-view-header_previewBannerIcon__7Yicv {
    flex: 0 0 var(--icon-xl);
    block-size: var(--icon-xl);
    color: var(--display-onlight-tertiary);
}

.product-ui-view-header_previewBannerContent__IA7D5 {
    display: flex;
    align-items: center;
    gap: var(--space-8);
    flex: 1 1;
}

.product-ui-view-header_previewBannerText__qqEw1 {
    color: var(--display-onlight-primary);
}

.product-ui-outline-button-group_outlineButtonGroup__5Di2V {
    display: flex;
    align-items: center;
    block-size: var(--tube-height-xs);
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--ui-border);
    overflow: hidden;
}

    .product-ui-outline-button-group_outlineButtonGroup__5Di2V .product-ui-outline-button-group_iconWrapper__MkDEa {
        display: flex;
        justify-content: center;
        align-items: center;
        block-size: 26px;
        inline-size: 26px;
        border-radius: var(--border-radius-sm);
    }

    .product-ui-outline-button-group_outlineButtonGroup__5Di2V .product-ui-outline-button-group_iconWrapper__MkDEa.product-ui-outline-button-group_hovered__1pm70 {
            background-color: var(--actionable-quaternary-background-onhover);
        }

    .product-ui-outline-button-group_outlineButtonGroup__5Di2V .product-ui-outline-button-group_separator__7NgHC {
        block-size: calc(100% - var(--space-8));
        inline-size: 1px;
        background-color: var(--ui-border);
    }

    .product-ui-outline-button-group_outlineButtonGroup__5Di2V .product-ui-outline-button-group_buttonWrapper__UvE7R {
        padding: 0 9px;
    }

.product-ui-view_viewContainer__qP1ev {
    background-color: var(--white);
    inline-size: var(--view-container-inline-size, 100%);
    block-size: 100%;
    border-radius: inherit;
    position: relative;
    margin-inline: auto;
}

.product-ui-view_viewSection__1SEE8 {
    display: flex;
    flex-direction: column;
}

.product-ui-view_viewSection__1SEE8.product-ui-view_list__QdwpP,
    .product-ui-view_viewSection__1SEE8.product-ui-view_workspace__bojgn,
    .product-ui-view_viewSection__1SEE8.product-ui-view_activity__m_U1I,
    .product-ui-view_viewSection__1SEE8.product-ui-view_upcoming__hL_YS {
        padding: 0 var(--space-48) 0;
        max-inline-size: var(--container-width-xs, 698px);
        margin-inline: auto;
    }

.product-ui-view_viewSection__1SEE8.product-ui-view_empty__LFbLg {
        align-items: center;
        color: var(--display-onlight-secondary);
        text-align: center;
    }

.product-ui-view_viewSection__1SEE8 .product-ui-view_emptyStateImage__o5zaJ {
        max-inline-size: 344px;
        inline-size: 100%;
        block-size: auto;
        aspect-ratio: 344 / 270;
    }

.product-ui-view_viewSection__1SEE8.product-ui-view_workspace__bojgn {
        --viewSectionGap: 0;
    }

.product-ui-view_viewSection__1SEE8 > hr:nth-child(2) {
            margin-block-start: 0;
        }

.product-ui-view_viewSection__1SEE8 > hr:last-child {
            margin-block-end: 0;
        }

:where(.product-ui-view_header__eLwqz, .product-ui-view_viewSection__1SEE8) + .product-ui-view_viewSection__1SEE8:not(.product-ui-view_empty__LFbLg, .product-ui-view_workspace__bojgn) {
    margin-block-start: var(--space-32);
}

.product-ui-view_textContainer__w9iVa {
    display: flex;
    flex: 1 1;
    align-items: center;
    gap: var(--space-12);
    inline-size: 100%;
    color: var(--display-onlight-primary);
}

.product-ui-view_sectionHeader__u2DCg {
    display: flex;
    align-items: center;
    min-block-size: var(--tube-height-sm);
    color: var(--display-onlight-primary);
}

.product-ui-view_boardView__Sp55G {
    position: absolute;

    --column-width: 15.625rem; /* 250px */

    padding: 0 var(--space-48) 0;
    display: flex;
    gap: var(--space-12);
}

.product-ui-view_boardView__Sp55G .product-ui-view_viewSection__1SEE8 {
        inline-size: var(--column-width);
        gap: var(--space-12);
        margin-block-start: 0;
    }

.product-ui-view_boardView__Sp55G.product-ui-view_customPosition__IP7PM {
        position: absolute;
        right: var(--content-x, 0);
        top: var(--content-y, 0);
    }

.product-ui-view_subheader__k7IDA {
    padding-block-start: var(--space-16);
    border-bottom: 1px solid var(--ui-border);
}

.product-ui-view_subheader__k7IDA .product-ui-view_viewDates__MTu8Y {
        display: flex;
    }

.product-ui-view_upcomingViewDate__00lRk {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 1;
    block-size: 44px;

    gap: var(--space-4);
    padding-block-start: var(--space-4);
}

.product-ui-view_upcomingViewDate__00lRk > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;

        text-align: center;
        inline-size: 100%;
    }

.product-ui-view_upcomingViewDate__00lRk .product-ui-view_weekday__P3eL6 {
        min-inline-size: 26px;
        color: var(--display-onlight-secondary);
    }

.product-ui-view_upcomingViewDate__00lRk .product-ui-view_date__zsYGP {
        color: var(--date-color, var(--display-onlight-primary));
    }

.product-ui-view_upcomingViewDate__00lRk.product-ui-view_past__rDhsZ {
        --date-color: var(--display-onlight-tertiary);
    }

.product-ui-view_upcomingViewDate__00lRk.product-ui-view_today__yIS0f {
        --date-color: var(--productui-display-color);
    }

.product-ui-view_upcomingViewDate__00lRk .product-ui-view_divider__7VPpl {
        display: block;
        block-size: 2px;
        margin-block-end: -1px;
        background-color: var(--productui-primary-theme-color);
    }

.product-ui-view_calendarView__QGq2b {
    display: grid;
    grid-template-columns: repeat(7, calc(100% / 7));
    grid-auto-rows: var(--product-ui-calendary-day-height, 92px);
}

.product-ui-view_calendarView__QGq2b > * {
        border-top: 1px solid var(--ui-border);
        border-right: 1px solid var(--ui-border);
    }

.product-ui-view_calendarView__QGq2b > *:nth-child(7n) {
            border-right: none;
        }

.product-ui-view_weeklyCalendarView__MoIzz {
    /* The hour the view should be scrolled to. */
    --hour-anchor: 0;
    --day-count: 4;

    block-size: 100%;
    display: flex;
    flex-direction: column;
}

.product-ui-view_weeklyCalendarView__MoIzz .product-ui-view_weeklyCalendarViewDetails__HUXqc {
        overflow: hidden;
        position: relative;
        flex: 1 1;
    }

.product-ui-view_weeklyCalendarView__MoIzz .product-ui-view_weeklyCalendarViewDetails__HUXqc::after {
            content: "";
            position: absolute;
            border-top: 1px solid var(--product-ui-divider-secondary);
            top: 0;
            left: 0;
            inline-size: var(--space-64);
            block-size: calc(var(--hour-height) / 2);
            background: linear-gradient(white 50%, transparent);
        }

.product-ui-view_weeklyCalendarView__MoIzz .product-ui-view_weeklyCalendarViewDetails__HUXqc .product-ui-view_weeklyCalendarViewAbsoluteContainer__71WHM {
            position: absolute;
            inset: 0;
            top: calc(var(--hour-anchor) * var(--hour-height) * -1);
        }

.product-ui-view_weeklyCalendarView__MoIzz .product-ui-view_weeklyCalendarViewDetails__HUXqc .product-ui-view_weeklyCalendarViewAbsoluteContainer__71WHM .product-ui-view_weeklyCalendarViewContainer__l_we4 {
                display: grid;
                inline-size: 100%;
                grid-template-columns: 66px repeat(var(--day-count), 1fr);
            }

.product-ui-weekly-calendar-day_weeklyCalendarDay__bfvcP {
    position: relative;
    block-size: calc(24 * var(--hour-height));
    border-left: 1px solid var(--product-ui-divider-secondary);

    display: flex;
    flex-direction: column;
}

    .product-ui-weekly-calendar-day_weeklyCalendarDay__bfvcP .product-ui-weekly-calendar-day_hour__DR88g {
        border-top: 1px solid var(--product-ui-divider-secondary);
        inline-size: 100%;
        block-size: var(--hour-height);
    }

.product-ui-weekly-calendar-day_weeklyCalendarDayTimes__uELJp {
    display: flex;
    flex-direction: column;
    position: relative;
}

.product-ui-weekly-calendar-day_weeklyCalendarDayTimes__uELJp .product-ui-weekly-calendar-day_hour__DR88g {
        inline-size: 100%;
        padding-inline-end: var(--space-8);
        block-size: var(--hour-height);
    }

.product-ui-weekly-calendar-day_weeklyCalendarDayTimes__uELJp .product-ui-weekly-calendar-day_hour__DR88g .product-ui-weekly-calendar-day_legend__zyPCX {
            margin-block-start: calc(-1 * var(--space-8) - 1px);
        }

.product-ui-weekly-calendar-day_weeklyCalendarDayDates__EZywD {
    block-size: 28px;
    inline-size: calc(100% - var(--space-64));
    margin-inline-start: var(--space-64);
    display: grid;
    grid-template-columns: repeat(var(--day-count), 1fr);
}

.product-ui-weekly-calendar-day_weeklyCalendarDayDates__EZywD .product-ui-weekly-calendar-day_date__ztENe {
        display: flex;
        align-items: center;
        justify-content: center;
    }

.product-ui-weekly-calendar-day_weeklyCalendarDayDates__EZywD .product-ui-weekly-calendar-day_date__ztENe > span {
            margin-inline-start: 3px;
            display: flex;
            justify-content: center;
        }

.product-ui-weekly-calendar-day_weeklyCalendarDayDates__EZywD .product-ui-weekly-calendar-day_date__ztENe.product-ui-weekly-calendar-day_selected__77NAt {
            color: var(--display-onlight-primary);
            font-weight: 500;
        }

.product-ui-weekly-calendar-day_weeklyCalendarDayDates__EZywD .product-ui-weekly-calendar-day_date__ztENe.product-ui-weekly-calendar-day_selected__77NAt > span {
                background-color: var(--productui-primary-theme-color);
                color: white;
                border-radius: var(--border-radius-sm);
                padding-inline: var(--space-4);
                min-inline-size: 20px;
                block-size: 20px;
                display: flex;
                align-items: center;
            }

.product-ui-weekly-calendar-day_legend__zyPCX {
    color: var(--display-onlight-secondary);
    text-align: right;
    font-weight: 400;
}

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 {
    --fold-hour: 0;

    margin-block-start: -9px;
    position: absolute;
    top: calc(var(--fold-hour) * var(--hour-height));
    left: 0;
    inline-size: 100%;
    block-size: 18px;

    display: grid;
    grid-template-columns: 66px 1fr;
    align-items: center;
}

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_time__TAUSe {
        text-align: right;
        margin-inline-start: calc(-1 * var(--space-12));
        background-color: white;
    }

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_time__TAUSe > p {
            color: var(--productui-primary-theme-color);
            margin-inline-end: var(--space-8);
        }

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_time__TAUSe {
        position: relative;
}

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_time__TAUSe::before {
            position: absolute;
            content: "";
            top: calc(-1 * var(--space-8));
            left: 0;
            right: 0;
            block-size: var(--space-8);
            background: linear-gradient(transparent, white 50%);
        }

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_time__TAUSe::after {
            position: absolute;
            content: "";
            top: 100%;
            left: 0;
            right: 0;
            block-size: var(--space-8);
            background: linear-gradient(white 50%, transparent);
        }

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_fold__R_VGr {
        block-size: 100%;
        position: relative;
    }

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_fold__R_VGr::before {
            content: "";
            position: absolute;
            block-size: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--productui-primary-theme-color);
            opacity: 0.5;
        }

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_fold__R_VGr .product-ui-weekly-calendar-day_highlightedCircle__4bI5l {
            position: absolute;
            left: calc(100% / var(--day-count) * var(--current-day-position) - 5px);
            top: 50%;
            transform: translateY(-50%);
            border-radius: 100%;
            inline-size: 11px;
            block-size: 11px;
            background-color: var(--productui-primary-theme-color);
            border: 1px solid white;
        }

.product-ui-weekly-calendar-day_weeklyCalendarViewFold__wvep5 .product-ui-weekly-calendar-day_fold__R_VGr .product-ui-weekly-calendar-day_highlightedFold__Qtqkf {
            position: absolute;
            left: calc(100% / var(--day-count) * var(--current-day-position));
            inline-size: calc(100% / var(--day-count));
            block-size: 2px;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--productui-primary-theme-color);
        }

.product-ui-weekly-calendar-day_weeklyCalendarAllDays__kCgAv {
    min-block-size: var(--space-32);
    border-top: 1px solid var(--product-ui-divider-secondary);
    border-bottom: 1px solid var(--product-ui-divider-secondary);
    inline-size: 100%;
    display: grid;
    grid-template-columns: 66px repeat(var(--day-count), 1fr);
}

.product-ui-weekly-calendar-day_weeklyCalendarAllDays__kCgAv .product-ui-weekly-calendar-day_allDayLegend__YiwAE {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-inline-end: var(--space-8);
    }

.product-ui-weekly-calendar-day_weeklyCalendarAllDays__kCgAv .product-ui-weekly-calendar-day_allDayLegend__YiwAE p {
            font-weight: 400;
            padding-block-start: var(--space-2);
        }

.product-ui-weekly-calendar-day_weeklyCalendarAllDays__kCgAv > div:not(:first-child) {
            border-left: 1px solid var(--product-ui-divider-secondary);
        }

.product-ui-weekly-calendar-day_weeklyCalendarAllDays__kCgAv > div {
        block-size: 100%;
        display: flex;
        flex-direction: column;
        padding-block: var(--space-2);
        padding-inline-start: var(--space-2);
        gap: var(--space-2);
}

.product-ui-task-circle_taskCircle__0rGd6 {
    --task-circle-border: var(--display-onlight-tertiary);
    --task-circle-size: 24px;
    --circle-border-width: 2px;
    --circle-pattern: solid;

    display: flex;
    align-items: center;
    inline-size: var(--task-circle-size);
    block-size: var(--task-circle-size);
    background-color: var(--task-circle-background, transparent);
    border: var(--circle-border-width) var(--circle-pattern) var(--task-circle-border);
    border-radius: var(--border-radius-full);
    color: var(--task-circle-border);
}

    .product-ui-task-circle_taskCircle__0rGd6.product-ui-task-circle_checked__neW_w {
        --task-circle-background: var(--task-circle-border);
        color: #fff;
    }

    .product-ui-task-circle_taskCircle__0rGd6.product-ui-task-circle_calendar__Ocykv {
        --task-circle-size: 12px;
        --circle-border-width: 1.5px;
    }

    .product-ui-task-circle_taskCircle__0rGd6.product-ui-task-circle_calendar__Ocykv.product-ui-task-circle_futureOccurrence__THcLN {
            --circle-pattern: dashed;
        }

    .product-ui-task-circle_taskCircle__0rGd6.product-ui-task-circle_subtitle__Y1IWe {
        --task-circle-size: 20px;
    }

.product-ui-task-circle_p4__lGtdq {
    --circle-border-width: 1px;
}

.product-ui-task-circle_p4__lGtdq.product-ui-task-circle_calendar__Ocykv {
        --circle-border-width: 1px;
    }

/* We have to flip it here because of the transparency of onlight tertiary, 
        otherwise the border appears darker as it's two layers of the colour */

.product-ui-task-circle_p4__lGtdq.product-ui-task-circle_checked__neW_w {
        --task-circle-border: transparent;
        --task-circle-background: var(--display-onlight-tertiary);
    }

.product-ui-task-circle_p3__8bLdg {
    --task-circle-border: var(--productui-meta-blue);
    --task-circle-background: rgb(var(--productui-meta-blue-rgb) / 0.1);
}

.product-ui-task-circle_p2__tXbCh {
    --task-circle-border: var(--productui-meta-orange);
    --task-circle-background: rgb(var(--productui-meta-orange-rgb) / 0.1);
}

.product-ui-task-circle_p1__VR5hk {
    --task-circle-border: var(--productui-meta-red);
    --task-circle-background: rgb(var(--productui-meta-red-rgb) / 0.1);
}

.product-ui-weekly-calendar-all-day-info_weeklyCalendarAllDayInfo__eXsCA {
    display: flex;
    align-items: center;
    padding-inline-start: var(--space-4);
    max-inline-size: calc(100% - var(--space-4));
    block-size: 20px;
    border-radius: 5px;
    border: 1px solid transparent;
    color: var(--display-onlight-secondary);
}

    .product-ui-weekly-calendar-all-day-info_weeklyCalendarAllDayInfo__eXsCA > p {
        padding-inline: 1px;
        max-inline-size: 100%;
        block-size: 100%;
        flex-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.6;
    }

    .product-ui-weekly-calendar-all-day-info_weeklyCalendarAllDayInfo__eXsCA.product-ui-weekly-calendar-all-day-info_withTaskCollapseIcon__ML3QB {
        border-color: var(--product-ui-inputs-border-idle);
    }

    .product-ui-weekly-calendar-all-day-info_weeklyCalendarAllDayInfo__eXsCA.product-ui-weekly-calendar-all-day-info_withTaskCollapseIcon__ML3QB p {
            padding-inline: 0;
        }

    .product-ui-weekly-calendar-all-day-info_weeklyCalendarAllDayInfo__eXsCA.product-ui-weekly-calendar-all-day-info_withTaskCollapseIcon__ML3QB > svg {
            flex-shrink: 0;
            min-inline-size: 0;
            margin-inline-end: var(--space-4);
        }

    @media (max-width: 480px) {
            .product-ui-weekly-calendar-all-day-info_weeklyCalendarAllDayInfo__eXsCA.product-ui-weekly-calendar-all-day-info_withTaskCollapseIcon__ML3QB > svg {
                display: none;
            }
        }

.feature-item_featureItem__dKEVV {
    --icon-size: var(--icon-base); /* 18px */

    color: var(--feature-item-color, var(--display-onlight-secondary));
    min-block-size: 22px;
}

    .feature-item_tooltip__gpdEX .feature-item_featureItem__dKEVV:hover {
        cursor: help;
    }

    .feature-item_featureItem__dKEVV.feature-item_base__FfZqS {
        --icon-size: var(--icon-lg); /* 20px */
    }

    .feature-item_featureItem__dKEVV svg {
        margin-block-start: 3px;
        flex: 0 0 var(--icon-size);
        height: var(--icon-size);
        vertical-align: top;
        min-inline-size: var(--icon-size);
    }

.feature-item_icon__lFAdK {
    color: var(--display-onlight-tertiary);
    margin-inline-end: var(--space-8);
}

.feature-item_icon__lFAdK.feature-item_green__DzdFl {
        color: var(--display-onlight-color-green);
    }

.feature-item_icon__lFAdK.feature-item_yellow__ivqnG {
        color: var(--display-onlight-color-yellow);
    }

.feature-item_toggle__6MXpu {
    color: var(--display-onlight-tertiary);
    border-radius: var(--border-radius-full);
    margin-inline-start: var(--space-8);
}

.feature-item_tooltip__gpdEX {
    inline-size: 100%;
}

.feature-item_tooltip__gpdEX:not(:has(.feature-item_toggle__6MXpu)):focus-visible {
            border-radius: var(--border-radius-sm);
            box-shadow: var(--shadow-focus);
        }

.feature-item_tooltip__gpdEX:focus-visible {
        outline: none;
    }

.feature-item_tooltip__gpdEX:focus-visible .feature-item_toggle__6MXpu {
            box-shadow: var(--shadow-focus);
        }

.feature-item_badge__kEc57 {
    display: inline-block;
    position: relative;
    bottom: 1px;
}

.pricing-table_table__zPpik {
    inline-size: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    position: relative;
}

    .pricing-table_table__zPpik caption {
        padding: 0 var(--space-8) var(--space-12);
    }

    @media (min-width: 960px) {

    .pricing-table_table__zPpik caption {
            position: absolute;
            transform: translateY(calc(-100% + 4px))
    }
        }

    @media (max-width: 959px) {

    .pricing-table_table__zPpik caption {
            padding: 0 var(--space-8) 0
    }
        }

    .pricing-table_table__zPpik td,
    .pricing-table_table__zPpik th {
        padding: 11px 8px;
    }

    .pricing-table_table__zPpik th:first-child {
        width: 280px;
    }

    @media (max-width: 959px) {

    .pricing-table_table__zPpik thead th {
                padding: 16px 8px 12px
        }
            }

    @media (max-width: 959px) {

    .pricing-table_table__zPpik thead th:first-child {
                padding: 0;
                width: 0
        }
            }

    .pricing-table_table__zPpik thead th:last-child {
            width: 0;
            padding: 0;
        }

    .pricing-table_table__zPpik tbody tr {
            block-size: 2.75rem;
            position: relative;
            transform-style: preserve-3d;
        }

    @media (min-width: 960px) {
                .pricing-table_table__zPpik tbody tr:not(.pricing-table_notHoverable__f6xxl):hover {
                    --feature-item-color: var(--display-onlight-primary);
                }

                    .pricing-table_table__zPpik tbody tr:not(.pricing-table_notHoverable__f6xxl):hover::after {
                        content: "";
                        display: block;
                        position: absolute;
                        background-color: var(--ui-background);
                        width: calc(100% + 8px);
                        height: calc(var(--height, 100%) - 10px);
                        left: 0;
                        top: 0;
                        transform: translateZ(-1px) translateY(5px) translateX(-4px);
                        border-radius: var(--border-radius-base);
                        pointer-events: none;
                    }
            }

    .pricing-table_table__zPpik tbody th,
        .pricing-table_table__zPpik tbody td {
            border-top: 1px solid var(--box-outline-border);
        }

    .pricing-table_table__zPpik tbody tr:last-child th,
        .pricing-table_table__zPpik tbody tr:last-child td {
            border-bottom: 1px solid var(--box-outline-border);
        }

    @media (max-width: 959px) {
            .pricing-table_table__zPpik tbody th {
                position: absolute;
                top: 0;
                border-top: none;
                border-bottom: none;
            }

            .pricing-table_table__zPpik tbody td {
                padding-block-start: 40px;
            }

            .pricing-table_table__zPpik tbody tr:last-child th {
                border-bottom: none;
            }
        }

.pricing-table_icon__5EBui {
    inline-size: 18px;
    block-size: 18px;
    vertical-align: top;
    margin-block-start: 2px;
}

.pricing-table_visuallyHidden__Vj3IV:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.pricing-table_secondary__bun_S {
    color: var(--display-onlight-secondary);
}

.pricing-table_tertiary__y4DGZ {
    color: var(--display-onlight-tertiary);
}

.pricing-table_table__zPpik.pricing-table_pricesTable__FMFUa td {
        padding-block-start: 16px;
    }

.pricing-table_planNamesSticky__AkXy0 {
    position: sticky;
    top: 72px;
    z-index: var(--z-index-sticky);
    background: var(--box-outline-background);
    border-bottom: 1px solid var(--box-outline-border);
    padding: var(--space-16) 0 var(--space-12) 278px;
    margin-bottom: var(--space-64);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

@media (max-width: 959px) {

.pricing-table_planNamesSticky__AkXy0 {
        display: none
}
    }

.pricing-table_plan__6xtQi {
    padding-inline: var(--space-8);
}

.pricing-table_buttonContainer__SVQI3 {
    display: flex;
    padding-block-start: var(--space-32);
}

.pricing-table_buttonContainer__SVQI3 a {
        flex: 1 1;
    }

.big-button_buttonWrapper__FJTDR {
    --ease-up: linear(0, 0.941 11.2%, 1.203 18.3%, 1.196 22.7%, 0.962 40.7%, 1.007 62.9%, 1);
    --duration-up: 500ms;

    --ease-hover: linear(0, 0.804 19.5%, 1.02 29.6%, 1.054 38.2%, 1.001 64.3%, 1);
    --duration-hover: 300ms;

    --ease-down: cubic-bezier(0.3, 0.7, 0.3, 1);
    --duration-down: 150ms;

    --shadow-size: 6px;

    display: inline-flex;
    position: relative;
    padding-block-end: var(--shadow-size);

    --shadow-texture-left: 10px;
    --shadow-texture-right-offset: 53px;
}

    .big-button_buttonWrapper__FJTDR:has(.big-button_bigButton__XG6GD:hover) {
        --shadow-texture-left: 12px;
        --shadow-texture-right-offset: 51px;
    }

.big-button_bigButton__XG6GD {
    --background-color-onhover: var(--background-color);
    --button-gap: 0;

    transition-property: transform;
    transition-duration: var(--duration-up);
    transition-timing-function: var(--ease-up);

    position: relative;
    overflow: hidden;
    inline-size: -webkit-fill-available;
    inline-size: -moz-available;
    inline-size: stretch;

    z-index: 1;

    /* Safari rounded corners bug fix: https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
    will-change: transform;
    -webkit-mask-image: radial-gradient(white, black);
            mask-image: radial-gradient(white, black);
}

.big-button_bigButton__XG6GD:hover {
        transition-timing-function: var(--ease-hover);
        transition-duration: var(--duration-hover);

        transform: translateY(1.5px);
    }

.big-button_bigButton__XG6GD:hover > svg:not(.big-button_shine__PmDS_) {
            transition-timing-function: var(--ease-hover);
            transition-duration: var(--duration-hover);

            transform: translate(-2px, 3px);
        }

.big-button_bigButton__XG6GD:active:not(.big-button_disabled__Cdw8Q, [aria-pressed="true"]) {
        transition-duration: var(--duration-down);
        transition-timing-function: var(--ease-down);

        transform: translateY(5px);
    }

.big-button_bigButton__XG6GD:active:not(.big-button_disabled__Cdw8Q, [aria-pressed="true"]) .big-button_circle__j9vMF {
            --scale: 1;
        }

.big-button_bigButton__XG6GD > span {
        z-index: 10;
    }

.big-button_circle__j9vMF {
    --scale: 0;

    border-radius: 999px;
    background-color: #ee6449;
    width: 110%;
    aspect-ratio: 1 / 1;
    position: absolute;
    z-index: 3;
    filter: blur(12px);

    transform: scale(var(--scale));
    transition-duration: var(--duration-down);
    transition-timing-function: var(--ease-down);
    transform-origin: center center;
}

.big-button_shadow__yHInR {
    position: absolute;
    top: 50%;
    left: 0;
    inline-size: 100%;
    block-size: 50%;
    border-radius: 13px;
    z-index: 0;
    overflow: hidden;

    background: var(--productui-background-dark);
}

.big-button_shadow__yHInR::after {
        content: "";
        display: block;
        position: absolute;
        left: var(--shadow-texture-left);
        bottom: 0;
        block-size: 20px;
        inline-size: calc(100% - var(--shadow-texture-left) - var(--shadow-texture-right-offset));
        background: var(--productui-background-dark);
        box-shadow: var(--productui-background-light) 0 0 18px;

        transition-timing-function: var(--ease-hover);
        transition-duration: var(--duration-hover);
    }

svg.big-button_glareLeft__lBF1N,
svg.big-button_glareRight__Y7s0K {
    position: absolute;
    color: var(--background-color);

    filter: drop-shadow(#ee6449 8px 8px 6px);
    inline-size: 166px;
    block-size: 65px;
}

svg.big-button_glareLeft__lBF1N {
    left: -125px;
    top: -13px;
    z-index: 1;
}

svg.big-button_glareRight__Y7s0K {
    right: -64px;
    bottom: 0;
    z-index: 0;
}

svg.big-button_shine__PmDS_ {
    inline-size: 218px;
    block-size: 84px;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    filter: blur(6px);
}

.prefooter_container__j_vmi {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: var(--space-24);
}

    .prefooter_container__j_vmi p {
        color: var(--display-onlight-secondary);
    }

    .prefooter_container__j_vmi .prefooter_buttons__eoBey {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--space-12);
    }

.prefooter_background__rD6wm {
    background: linear-gradient(0deg, var(--composition-background-red) 58%, transparent 100%);
}

.cta_backgroundWrapper__66dcJ {
    background: linear-gradient(
        var(--composition-background-neutral) 0,
        var(--composition-background-red) 58%
    );
}

.cta_subtitle__4jjW0 {
    color: var(--display-onlight-secondary);
}

.cta_contentContainer__iLr0y {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.cta_awardsContainer__VSgE8 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    gap: var(--gap, var(--space-64));
}

@media (max-width: 768px) {

.cta_awardsContainer__VSgE8 {
        --gap: var(--space-32)
}
    }

@media (max-width: 480px) {

.cta_awardsContainer__VSgE8 {
        --gap: var(--space-16)
}
    }

@media (min-width: 769px) {

.cta_awardMiddle__KW7nI {
        padding-block-end: var(--space-24)
}
    }

.intro_intro___LqqL {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    padding-block-start: var(--space-32);
    padding-block-end: var(--space-32);
    position: relative;
}

    @media (max-width: 768px) {.intro_intro___LqqL {
        padding-block-end: var(--space-16)
}
    }

    .intro_intro___LqqL h1 {
        color: var(--display-onlight-primary);
    }

    @media (max-width: 768px) {
            .intro_intro___LqqL h1 br {
                display: none;
            }
        }

    .intro_intro___LqqL p {
        max-inline-size: 650px;
        color: var(--display-onlight-secondary);
    }

.plans_planControls__k1HRu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pricing-plan-header_planHeaderContainer__Q_sTM {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    margin-block-end: var(--space-24);
}

.pricing-plan-header_contentContainer__X6y_d {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.pricing-plan-header_contentContainer__X6y_d.pricing-plan-header_sm__uDeMl {
        --illustration-height: 75px;
    }

@media (max-width: 768px) {

.pricing-plan-header_contentContainer__X6y_d {
        flex-direction: row-reverse;
        justify-content: space-between
}
    }

.pricing-plan-header_pricingContainer__GgDb7 {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    margin-block-start: auto;
}

.pricing-plan-header_textContainer__5W1Zb {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);
}

@media (max-width: 768px) {

.pricing-plan-header_textContainer__5W1Zb {
        justify-content: center
}
    }

.pricing-plan-header_additionalInfo__ZnHhq {
    margin-block-end: calc(var(--space-12) * -1);
}

.pricing-plan-header_colorSecondary__Gmo6D {
    color: var(--display-onlight-secondary);
}

.pricing-plan-header_planImage__xke6z {
    block-size: var(--illustration-height, 100px);
    inline-size: auto;
    align-self: flex-start;
    -o-object-fit: contain;
       object-fit: contain;
}

.showcase_showCaseContainer__kxKHc {
    --padding: 2.5rem;

    display: flex;
    flex-direction: column;
    gap: var(--showCaseGap, var(--space-96));
}

    @media (max-width: 768px) {.showcase_showCaseContainer__kxKHc {
        --showCaseGap: var(--space-64)
}
    }

    @media (max-width: 1120px) {.showcase_showCaseContainer__kxKHc {
        --padding: 1.5rem
}
    }

.showcase_personalPlansContainer__s8kAJ {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    grid-gap: 0;
    gap: 0;
    background: var(--box-outline-background);
    border: 1px solid var(--box-outline-border);
    border-radius: var(--border-radius-lg);
}

@media (max-width: 768px) {

.showcase_personalPlansContainer__s8kAJ {
        grid-auto-flow: row
}
    }

.showcase_column__gOGRf {
    position: relative;
    padding: 36px var(--space-48);
}

.showcase_column__gOGRf:not(:last-child) {
        border-right: 1px solid var(--box-outline-border);
    }

@media (min-width: 769px) {

.showcase_column__gOGRf {
        display: grid;
        grid-row: 1 / 3;
        grid-template-rows: subgrid
}
    }

.showcase_column__gOGRf .showcase_badge__30YOk {
        position: absolute;
        top: var(--space-8);
        right: var(--space-8);
    }

@media (max-width: 768px) {

.showcase_column__gOGRf {
        padding: var(--space-24) var(--space-32)
}

        .showcase_column__gOGRf:not(:last-child) {
            border-right: none;
        }

        .showcase_column__gOGRf .showcase_badge__30YOk {
            display: none;
        }
    }

.showcase_benefitsContainer__OAxUn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.showcase_benefitsContainer__OAxUn .showcase_buttonContainer__qo5QS {
        display: flex;
        padding-block-start: var(--space-32);
    }

.showcase_benefitsContainer__OAxUn .showcase_buttonContainer__qo5QS a {
            flex: 1 1;
        }

.showcase_workspaceInfo__rtn8O {
    flex: 1 1;
}

@media (min-width: 1120px) {

.showcase_workspaceInfo__rtn8O {
        border-right: 1px solid var(--ui-border);
        padding-inline-end: var(--padding)
}
    }

@media (max-width: 1120px) {

.showcase_workspaceInfo__rtn8O {
        margin-block-end: var(--space-32)
}
    }

@media (max-width: 959px) {

.showcase_workspaceInfo__rtn8O {
        margin-block-end: var(--space-16)
}
    }

.showcase_plan__A_aBJ {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    flex: 1 1;
}

.showcase_colorRed__zeGbf {
    color: var(--display-onlight-color-red);
}

.showcase_colorSecondary___0y0P {
    color: var(--display-onlight-secondary);
}

.showcase_designedForTeams__3n0Nx {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(85px, -60px);
    flex-direction: column;
    max-inline-size: 170px;
}

.showcase_designedForTeams__3n0Nx > div {
        align-self: center;
    }

.showcase_designedForTeams__3n0Nx svg {
        transform: translateX(-30px);
    }

.pricing-team_pageWrapper__D2NId {
    background-color: var(--composition-background-neutral);
}

.product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh {
    /* Height is based on the duration of the task. */
    --weekly-calendar-height: 50px;
    block-size: var(--weekly-calendar-height);

    --priority-bg-color: var(--product-ui-background-priority-4);
    background-color: oklch(from var(--priority-bg-color) l c h / 60%);

    inline-size: calc(100% - var(--space-4));
    border-radius: 5px;
    padding: 2px var(--space-4);
    display: flex;
    gap: var(--space-4);
    min-block-size: 20px;
}

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_taskCircle__IZfBv {
        inline-size: var(--space-12);
        block-size: var(--space-16);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        min-inline-size: 0;
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_taskCircle__IZfBv > div:first-child {
            flex-shrink: 0;
            flex-grow: 0;
        }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: var(--space-2);
        max-inline-size: calc(100% - var(--space-16));
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O .product-ui-weekly-calendar-task_text___sFfl {
            text-align: left;
            color: var(--display-onlight-primary);
            line-height: 1.3;
            max-inline-size: calc(100% - var(--space-4));
        }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O .product-ui-weekly-calendar-task_repeat__32iKD {
            color: var(--display-onlight-secondary);
            line-height: 1.3;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: var(--space-2);
            flex-shrink: 0;
        }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O > svg {
            flex-shrink: 0;
        }

    /* If the duration is less than an hour, show the task title on one line. */

    @media (max-width: 768px) {.product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh {
        container-name: compact;
        container-type: inline-size
}
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_compact__y5amU {
        container-name: compact;
        container-type: inline-size;
    }

    @container compact (min-width: 0px) {
        .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O {
            max-inline-size: calc(100% - var(--space-16));
        }

            .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O .product-ui-weekly-calendar-task_text___sFfl {
                flex-wrap: nowrap;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                block-size: var(--space-16);
            }

            .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O.product-ui-weekly-calendar-task_withRepeat__DtF_V {
                flex-direction: row;
                align-items: center;
                block-size: var(--space-16);
            }
                .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O.product-ui-weekly-calendar-task_withRepeat__DtF_V p {
                    max-inline-size: 124px;
                }
                .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh .product-ui-weekly-calendar-task_right__gHc9O.product-ui-weekly-calendar-task_withRepeat__DtF_V svg {
                    color: var(--display-onlight-secondary);
                    block-size: var(--space-12);
                }
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh {
    --weekly-calendar-start-time-offset: 0px;
}

    /* The task is meant to be displayed on a calendar day. */

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_inContext__WTxGN {
        position: absolute;
        top: var(--weekly-calendar-start-time-offset);
    }

    /* Compute background color based on priority */

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_p4__jecOj {
        --priority-color: var(--product-ui-priority-4);
        --priority-bg-color: var(--product-ui-background-priority-4);
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_p3__XHUjM {
        --priority-color: var(--product-ui-priority-3);
        --priority-bg-color: var(--product-ui-background-priority-3);
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_p2__VCc1N {
        --priority-color: var(--product-ui-priority-2);
        --priority-bg-color: var(--product-ui-background-priority-2);
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_p1__4j75I {
        --priority-color: var(--product-ui-priority-1);
        --priority-bg-color: var(--product-ui-background-priority-1);
    }

    /* Uncompletable tasks shows differently */

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_notCompletable__bEMio {
        background-color: white;
        border: 1px solid var(--product-ui-inputs-border-idle);
    }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_notCompletable__bEMio .product-ui-weekly-calendar-task_right__gHc9O {
            max-inline-size: calc(100% - var(--space-8));
        }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_notCompletable__bEMio .product-ui-weekly-calendar-task_taskCircle__IZfBv {
            inline-size: 5px;
            block-size: 100%;
            position: relative;
        }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_notCompletable__bEMio .product-ui-weekly-calendar-task_taskCircle__IZfBv:after {
                position: absolute;
                content: "";
                border-radius: var(--space-2);
                top: 0;
                left: 0;
                right: var(--space-2);
                bottom: 0;
                background-color: var(--priority-color);
            }

    .product-ui-weekly-calendar-task_weeklyCalendarTask__ZiTXh.product-ui-weekly-calendar-task_dragging__91yv7 {
        opacity: 0.7;
    }

.apps-block_layout__2ubmc {
    inline-size: 100%;
}

.apps-block_header__JgcNT {
    display: flex;
    justify-content: center;
}

.apps-block_illustrationContainer___TKrl {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 680px;
    overflow: hidden;
}

.apps-block_illustration__ZbRcl {
    inline-size: 100%;
    min-inline-size: 450px;
    block-size: auto;
    margin-inline-start: 50%;
    transform: translateX(-50%);
}

.apps-block_alertSection__AWsYt {
    text-align: center;
}

.button_buttonWithPricing__xlLSb {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-16);
}

    @media (max-width: 959px) {.button_buttonWithPricing__xlLSb {
        flex-direction: column
}
    }

    .button_buttonWithPricing__xlLSb sup {
        vertical-align: top;
        margin-top: 1px;
    }

.button_onlightSecondary___3bbW {
    color: var(--display-onlight-secondary);
}

.cards-block_header__Eh_Vs {
    display: flex;
    justify-content: center;
}

.cards-block_grid__Rp64E {
    --min-item-size: 215px;

    display: grid;
    grid-gap: var(--space-12);
    gap: var(--space-12);
    grid-template-columns: repeat(auto-fit, minmax(min(var(--min-item-size), 100%), 1fr));
    justify-content: center;
    inline-size: 100%;
}

@media (max-width: 959px) {

.cards-block_grid__Rp64E:has(> :last-child:nth-child(4)) {
            /* force two columns on md screen */
            --min-item-size: 250px
    }
        }

.cards-block_wrapper__4E5cy {
    inline-size: 100%;
}

.cards-block_container__79KDy {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-24);
}

.cards-block_accessoryView__6_EVr {
    color: var(--display-onlight-secondary);
    display: flex;
    align-items: center;
    gap: var(--space-4);
}

.cards-block_accessoryView__6_EVr > div {
        block-size: var(--space-24);
        inline-size: var(--space-24);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-block-start: 1px;
    }

.cards-block_accessoryView__6_EVr > div svg {
            color: var(--display-onlight-tertiary);
        }

.cards-block_iconCard__slO92 {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.cards-block_link__awxJB {
    outline: 0;
}

.cards-block_link__awxJB:hover {
        text-decoration: none;
    }

.cards-block_link__awxJB::after {
        position: absolute;
        content: "";
        inset: 0;
    }

.cards-block_imageContainer__JWSss {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 11.5625rem;
    background-color: var(--bg-color, white);
    border-top-left-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);
}

.cards-block_textContainer__vjopq {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 4.125rem;
}

.pricing-info_pricingInfo__V4kTa {
    display: flex;
    align-items: center;
    gap: var(--space-8);
    min-block-size: 2.25rem;
    position: relative;
}

    @media (max-width: 959px) {.pricing-info_pricingInfo__V4kTa {
        align-items: flex-start
}
    }

.pricing-info_priceGroup__HobAK {
    display: flex;
    gap: var(--space-2);
}

.pricing-info_currency__WRwtK {
    color: var(--display-onlight-secondary);
    font-weight: var(--font-weight-regular);
    position: absolute;
    left: 0;
    transform: translateX(-100%);
}

.pricing-info_price__fxkah {
    --min-font-size: 2;
    --max-font-size: 2;
    line-height: 1;
    letter-spacing: -0.02em;
    font-weight: var(--font-weight-regular);
}

.pricing-info_details__HN9rU {
    color: var(--display-onlight-tertiary);
}

.pricing-box_wrapper__OY2v8 {
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: var(--bg-position, top center);
    inline-size: 100%;
    margin-block-end: calc(var(--space-96) * -1);
}

    @media (max-width: 768px) {.pricing-box_wrapper__OY2v8 {
        --bg-position: center
}
    }

.pricing-box_container__Shxht {
    padding-block-end: var(--space-96);
}

.pricing-box_top__6mahb {
    display: flex;
    flex-direction: var(--top-direction, row);
    gap: var(--top-gap, var(--space-24));
    align-items: center;
}

@media (max-width: 768px) {

.pricing-box_top__6mahb {
        --top-direction: column;
        --top-gap: var(--space-32)
}
    }

.pricing-box_left__3r_TA {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    min-inline-size: var(--min-width, 300px);
}

.pricing-box_left__3r_TA .pricing-box_subtitle__0b2mk {
        display: flex;
        gap: var(--space-8);
        align-items: center;
        color: var(--display-onlight-secondary);
    }

@media (max-width: 768px) {

.pricing-box_left__3r_TA {
        --min-width: 100%
}
    }

.pricing-box_right__t_xd_ {
    display: flex;
    inline-size: 100%;
}

@media (min-width: 769px) {

.pricing-box_right__t_xd_ {
        justify-content: center
}
    }

.pricing-box_illustration__RlEvd {
    margin-block-start: calc(var(--space-16) * -1);
}

.pricing-box_divider__vb2eQ {
    margin-block-start: var(--divider-margin, var(--space-24));
    inline-size: var(--divider-width, calc(100% + var(--space-48)));
}

@media (max-width: 768px) {

.pricing-box_divider__vb2eQ {
        --divider-margin: 0;
        --divider-width: 100%
}
    }

.pricing-box_pricingBox__nmOE6 {
    display: flex;
    flex-direction: column;
    gap: var(--box-gap, var(--space-24));
    align-items: center;

    max-width: 800px;
    padding: var(
        --box-padding,
        var(--space-48) calc(var(--space-48) + var(--space-24)) var(--space-24)
    );
    margin: 0 auto;

    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-xl);

    background-color: var(--elevated-background);
    box-shadow: var(--shadow-elevated);
}

@media (max-width: 768px) {

.pricing-box_pricingBox__nmOE6 {
        --box-gap: var(--space-32);
        --box-padding: var(--space-32) var(--space-24) var(--space-24)
}
    }

.pricing-box_buttonRow__OfAmE {
    display: flex;
    flex-direction: var(--button-row-direction, row);
    inline-size: 100%;
    gap: var(--space-12);
}

.pricing-box_buttonRow__OfAmE a {
        inline-size: 100%;
    }

@media (max-width: 768px) {

.pricing-box_buttonRow__OfAmE {
        --button-row-direction: column;
        margin-block-end: calc(var(--space-16) * -1)
}
    }

.pricing-box_featureItems__7OY70 {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.pricing-box_featureItem__9Su5H {
    display: flex;
    align-items: center;
    gap: var(--space-12);

    color: var(--display-onlight-secondary);
}

.pricing-box_handwrittenBoxWrapper__Foy91 {
    position: relative;
    inline-size: 100%;
    max-inline-size: 800px;
    margin: 0 auto;
}

.pricing-box_handwrittenBox__ZLvDw {
    position: absolute;
    top: var(--handwrittenBoxTop, 40px);
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    max-inline-size: 359px;
    inline-size: 100%;
}

.pricing-box_handwrittenBox__ZLvDw p {
        position: absolute;
        top: 50px;

        background-color: var(--composition-background-neutral);
        inline-size: var(--text-width, 335px);
    }

.pricing-box_handwrittenBox__ZLvDw svg {
        height: var(--arrowHeight, 313px);
    }

@media (max-width: 768px) {

.pricing-box_handwrittenBox__ZLvDw {
        --handwrittenBoxTop: 24px
}

        .pricing-box_handwrittenBox__ZLvDw p {
            --text-width: 286px;
        }
        .pricing-box_handwrittenBox__ZLvDw svg {
            --arrowHeight: 248px;
        }
    }

.feature-pages-block_grid__NaL2Y {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--space-12);
}

.feature-pages-block_iconCard__FWugE {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    inline-size: 100%;
    max-inline-size: var(--max-size, 230px);
}

@media (max-width: 768px) {

.feature-pages-block_iconCard__FWugE {
        --max-size: 100%
}
    }

@media (min-width: 1120px) {

.feature-pages-block_iconCard__FWugE {
        --max-size: 352px
}
    }

.feature-pages-block_link__4fPV4 {
    outline: 0;
}

.feature-pages-block_link__4fPV4:hover {
        text-decoration: none;
    }

.feature-pages-block_link__4fPV4::after {
        position: absolute;
        content: "";
        inset: 0;
    }

.feature-pages-block_imageContainer__cU5Xi {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 11.5625rem;
    background-color: var(--bg-color, white);
    border-top-left-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);
}

.feature-pages-block_textContainer__HqgdD {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 4.125rem;
}

.feature-pages-block_sectionHeader__oYkAw {
    margin-inline: auto;
}

.product-ui-task_taskContainer__b_oit {
    position: relative;
}

.product-ui-task_task__kf496 {
    --task-background: var(--white);
    background-color: var(--task-background);
    width: 100%;
    padding: var(--space-8) 0;
}

.product-ui-task_task__kf496.product-ui-task_subtask__zsjlr {
        margin-inline-start: 36px;
    }

.product-ui-task_board__tfZxh {
    padding: 14px 12px;
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--card-border-idle);
    border-color: var(--card-border-color, var(--card-border-idle));
    background: var(--card-background, var(--card-background-idle));
    box-shadow: var(--card-box-shadow, var(--shadow-card-idle));
}

.product-ui-task_board__tfZxh .product-ui-task_taskAttributeContainer__nI5ag {
        flex-direction: column;
        gap: var(--space-8);
    }

.product-ui-task_board__tfZxh.product-ui-task_hovered__Ev8EQ {
        --card-border-color: var(--card-border-onhover);
        --card-background: var(--card-background-onhover);
        --card-box-shadow: var(--shadow-card-onhover);
    }

.product-ui-task_taskDescription__LRUYl {
    margin: 0 0 8px 36px;
    color: var(--display-onlight-secondary);
}

.product-ui-task_taskDescription__LRUYl.product-ui-task_checked__UDOck {
        color: var(--display-onlight-tertiary);
    }

.product-ui-task_taskOverflowContainer__Tioxx {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    min-block-size: var(--tube-height-sm);
}

.product-ui-task_taskOverflowContainer__Tioxx > * {
        grid-column: 1;
        grid-row: 1;
    }

.product-ui-task_taskSection__1Hgge {
    display: flex;
    gap: var(--space-16);
    align-items: center;
}

.product-ui-task_taskInfo__vtXMS {
    flex: 1 1;
    display: flex;
    gap: var(--space-12);
    align-items: center;
}

.product-ui-task_taskCircle__VwzSi {
    flex: 0 1;
    display: flex;
    align-items: center;
    align-self: baseline;
    block-size: calc(16px * var(--body-sm-line-height));
}

.product-ui-task_taskContent__HmYnn {
    flex: 1 1;
}

.product-ui-task_projectSection__1gOSp {
    flex: 0 1 var(--basis, 20%);
    display: flex;
    align-items: center;
    gap: var(--space-4);
}

.product-ui-task_projectIcon__WshaF {
    color: var(--productui-primary-theme-color);
    flex-shrink: 0;
}

.product-ui-task_taskText__CW1sq {
    color: var(--display-onlight-primary);
}

.product-ui-task_taskText__CW1sq.product-ui-task_checked__UDOck {
        color: var(--display-onlight-tertiary);
        text-decoration: line-through;
        text-decoration-color: var(--ui-border);
    }

.product-ui-task_attributeButtonContainer__5XJne {
    display: flex;
    inline-size: 100%;
    background-color: var(--task-background);
    inline-size: -moz-min-content;
    inline-size: min-content;
    align-self: center;
    justify-self: end;
}

.product-ui-task_taskAttributeContainer__nI5ag {
    display: flex;
    gap: var(--space-8);
    margin-inline-start: 36px;
    padding-block-start: var(--space-4);
}

.product-ui-task_taskAttributeRow__Yl36d {
    display: flex;
    gap: var(--space-8);
    align-items: center;
}

.product-ui-task_taskAttribute__aVXTG {
    display: flex;
    align-items: center;
    gap: var(--space-4);
    color: var(--display-onlight-tertiary);
}

.product-ui-task_taskAttributeIcon__nbBsC {
    flex-shrink: 0;
    flex-grow: 0;
}

.product-ui-task_dueDateTomorrow__L5gOI {
    color: var(--display-onlight-color-orange);
}

.product-ui-task_dueDateToday__VSsgz {
    color: var(--display-onlight-color-green);
}

.product-ui-task_repeat__yRcwm {
    color: var(--display-onlight-color-green);
}

.product-ui-task_contextMenu__lN3E2 {
    position: absolute;
    top: var(--context-menu-y, 0);
    right: var(--context-menu-x, 0);
    min-inline-size: 10.625rem; /* 170px */
}

.grid_taskBackground__6hDDy {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.grid_hoverIncentive__8jax4 {
    max-inline-size: 200px;
    position: absolute;
    top: 30px;
    right: -120px;
}

@media (max-width: 1350px) {

.grid_hoverIncentive__8jax4 {
        position: relative;
        top: 0;
        max-inline-size: 230px;
        right: 0;
        left: 100%;
        transform: translateX(-110%) translateY(-24px)
}

        .grid_hoverIncentive__8jax4 svg {
            transform: rotate(-80deg) translateY(16px);
        }
    }

.grid_relative__qQDMz {
    position: relative;
}

.prefooter_prefooterBackground__l0Wz_ {
    background: linear-gradient(0deg, var(--composition-background-yellow) 58%, transparent 100%);
    width: 100%;
}

.prefooter_prefooter__hL46R {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.prefooter_prefooter__hL46R .prefooter_buttons__rYg9A {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--space-12);
        margin-block-start: var(--space-24);
    }

.prefooter_colorSecondary__D_tYs {
    color: var(--display-onlight-secondary);
}

.get-it-all-done-sequence_sequenceContainer__anumL
    *:where([data-productuiview="personal-workspace"], [data-productuiview="team-workspace"]) {
    display: none;
}

.get-it-all-done-sequence_sequenceContainer__anumL [data-productui="sidebarsection"] {
    overflow: hidden;
    block-size: 0;
}

.get-it-all-done-sequence_sequenceContainer__anumL [data-productui="cursor"] {
    inset: 50%;
    opacity: 0;
}

.product-ui-segmented-control_productUISegmentedControl__6dX5d {
    display: flex;
    gap: var(--space-4);

    padding: var(--space-2);
    border-radius: var(--border-radius-lg);
    background: var(--ui-background);
}

.product-ui-segmented-control_productUISegmentedControlItem__OwBxM {
    min-block-size: var(--tube-height-xs);
    border-radius: var(--border-radius-base);
    padding: 0.5px var(--space-16);
    display: flex;
    align-items: center;
}

.product-ui-segmented-control_productUISegmentedControlItem__OwBxM.product-ui-segmented-control_selected__2sBqC {
        background: var(--box-outline-background);
    }

.quick-add-sequence_sequenceContainer__Bzve_ :where([data-productui="quickAddText"], [data-productui="taskAttributePill"]) {
    display: none;
}

.quick-add-sequence_sequenceContainer__Bzve_ [data-productui="cursor"] {
    opacity: 0;
}

.flexible-views-sequence_sequenceContainer__UPY23 [data-productui="cursor"] {
    opacity: 0;
    inset: 50%;
}

.flexible-views-sequence_views__JFslS {
    transform: scale(0.98);
    transform-origin: top left;
    block-size: 100%;
}

.flexible-views-sequence_contextMenu__nNAHT {
    transform: scale(0.86);
    transform-origin: top right;
}

.today-view-sequence_contextContainer__9Ph1V {
    position: relative;
}

.today-view-sequence_contextMenu__dr_Et {
    position: absolute;
    inline-size: -moz-max-content;
    inline-size: max-content;
    inset-block-start: calc(100% + var(--space-4));
    inset-inline-end: 0;
    z-index: var(--product-ui-z-index-popup);
    visibility: hidden;
}

.today-view-sequence_sequenceContainer__10Qnf :where([data-hide]) {
    display: none;
}

.today-view-sequence_sequenceContainer__10Qnf [data-productui="cursor"] {
    opacity: 0;
    inset: 50%;
}

.today-view-sequence_taskSectionOrdered__I_jvn [data-productui-task="dentist"] {
        order: 1;
    }

.today-view-sequence_taskSectionOrdered__I_jvn [data-productui-task="shortcut"] {
        order: 2;
    }

.today-view-sequence_taskSectionOrdered__I_jvn [data-productui-task="yoga"] {
        order: 3;
    }

.today-view-sequence_taskSectionOrdered__I_jvn [data-productui-task="bread"] {
        order: 4;
    }

.simplify-planning-sequence_sequenceContainer__Hl0xZ [data-productui="cursor"] {
    opacity: 0;
    inset: 50%;
}

.text-lock-up_textLockUp__XR_vl {
    display: flex;
    flex-direction: column;
}

    .text-lock-up_textLockUp__XR_vl .text-lock-up_textContainer__pzVae {
        display: flex;
        flex-direction: column;
    }

    .text-lock-up_textLockUp__XR_vl .text-lock-up_buttonRow__UNTwd {
        display: flex;
        flex-direction: column;
        align-items: var(--text-lock-up-button-alignment, inherit);
    }

    .text-lock-up_textLockUp__XR_vl .text-lock-up_body__jD_N4 {
        color: var(--display-onlight-secondary);
    }

    .text-lock-up_textLockUp__XR_vl .text-lock-up_preheader__MTAZF {
        color: var(--text-lock-up-preheader-color, var(--display-onlight-color-red));
    }

    .text-lock-up_textLockUp__XR_vl.text-lock-up_lg__80wlX {
        gap: var(--container-gap, var(--space-32));
    }

    .text-lock-up_textLockUp__XR_vl.text-lock-up_lg__80wlX .text-lock-up_textContainer__pzVae {
            gap: var(--text-gap, var(--space-24));
        }

    .text-lock-up_textLockUp__XR_vl.text-lock-up_lg__80wlX .text-lock-up_buttonRow__UNTwd {
            gap: var(--space-12);
        }

    .text-lock-up_textLockUp__XR_vl.text-lock-up_base__N7cLk {
        gap: var(--space-24);
    }

    .text-lock-up_textLockUp__XR_vl.text-lock-up_base__N7cLk .text-lock-up_textContainer__pzVae {
            gap: var(--space-16);
        }

    .text-lock-up_textLockUp__XR_vl.text-lock-up_base__N7cLk .text-lock-up_buttonRow__UNTwd {
            gap: 14px;
        }

    @media (max-width: 768px) {
        .text-lock-up_textLockUp__XR_vl.text-lock-up_lg__80wlX {
            --container-gap: var(--space-24);
        }

            .text-lock-up_textLockUp__XR_vl.text-lock-up_lg__80wlX .text-lock-up_textContainer__pzVae {
                --text-gap: var(--space-16);
            }
    }

.prefooter_prefooterBackground__l_Z7L {
    background: linear-gradient(
        0deg,
        var(--background-color, --composition-background-yellow) 58%,
        transparent 100%
    );
    inline-size: 100%;
    padding-block-start: var(--space-192);
}

.prefooter_leftColumn__if4DN {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-32);
}

.prefooter_leftColumn__if4DN img {
        inline-size: 100%;
        max-inline-size: 372px;
        block-size: auto;
    }

@media (max-width: 768px) {

.prefooter_leftColumn__if4DN img {
            width: 226px
    }
        }

.prefooter_rightColumn__5_DvW {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 768px) {

.prefooter_rightColumn__5_DvW {
        text-align: center
}
    }

.app-collections_container__w2mzh {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-collections_grid__jJo1l {
    display: flex;
    justify-content: center;
    inline-size: 100%;
}

.app-collections_grid__jJo1l ul {
        display: flex;
        gap: var(--space-12);
        justify-content: center;
        inline-size: 100%;
    }

@media (max-width: 768px) {

.app-collections_grid__jJo1l ul {
            flex-direction: column
    }
        }

.app-collections_grid__jJo1l li {
        inline-size: 100%;
        max-inline-size: 354px;
    }

@media (max-width: 768px) {

.app-collections_grid__jJo1l li {
            max-inline-size: 100%
    }
        }

.category-grid_categorySection__576uh {
    align-items: center;
    display: flex;
    flex-direction: column;
}

    .category-grid_categorySection__576uh .category-grid_gridContainer__GVTna {
        display: grid;
        grid-gap: var(--space-12);
        gap: var(--space-12);
        grid-template-columns: repeat(auto-fit, var(--grid-item-size, 155px));
        justify-content: center;
        margin-block-end: var(--space-24);
        inline-size: 100%;
    }

    @media (min-width: 769px) {

    .category-grid_categorySection__576uh .category-grid_gridContainer__GVTna {
            --grid-item-size: 191px
    }
        }

.blog-cards_container__mlTfn {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: 100%; /* IE and Edge */
}

    .blog-cards_container__mlTfn ::-webkit-scrollbar {
        display: none;
    }

    .blog-cards_container__mlTfn {

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.blog-cards_horizontalScroll__GepK5 {
    display: flex;
    gap: var(--space-12);
    justify-content: center;

    max-inline-size: 1088px;
    inline-size: 100%;

    overflow-x: auto;
    scroll-snap-type: x mandatory;
}

@media (max-width: 959px) {

.blog-cards_horizontalScroll__GepK5 {
        justify-content: flex-start
}
    }

@media (max-width: 959px) {

.blog-cards_horizontalScroll__GepK5 {
        /* center the last blog card in the horizontal scroll */
}
        .blog-cards_horizontalScroll__GepK5::after {
            content: "";
            flex: 0 0 50%;
        }
    }

.blog-cards_column__s4Hoo {
    display: flex;
    gap: var(--space-12);
    scroll-snap-align: center;
    min-inline-size: 295px;
    max-inline-size: 347px;
}

.blog-cards_column__s4Hoo article {
        block-size: 100%;
    }

.blog-cards_column__s4Hoo article > img {
            min-block-size: var(--img-size, 156px);
        }

@media (min-width: 769px) {

.blog-cards_column__s4Hoo {
        --img-size: 185px
}
    }

@media (min-width: 769px) {.product-scenes-section_fullHeight__e0lvI {
        block-size: 100vh;
        max-block-size: 40rem /* 640px */
}
    }

.product-scenes-section_sceneContainer__tTnJW {
    --text-lock-up-button-alignment: flex-start;
}

@media (max-width: 768px) {

.product-scenes-section_sceneContainer__tTnJW {
        display: flex;
        flex-direction: column;
        gap: var(--space-96)
}
    }

.stats-box_statsBox__Bs2r2 {
    --img-width: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-16);
    text-align: center;
}

    .stats-box_statsBox__Bs2r2 .stats-box_statsBoxImgContainer__PlAVY {
        max-inline-size: var(--img-width);
    }

    .stats-box_statsBox__Bs2r2 .stats-box_statsBoxImgContainer__PlAVY img {
            inline-size: 100%;
            block-size: auto;
            -o-object-fit: contain;
               object-fit: contain;
        }

    @media (max-width: 959px) {.stats-box_statsBox__Bs2r2 {
        --img-width: 175px
}
    }

    .stats-box_statsBox__Bs2r2 .stats-box_statsBoxTextContainer___vY4e p:last-child {
            padding-top: var(--space-4);
            color: var(--display-onlight-secondary);
        }

.stats-section_stats__rel8z {
    display: flex;
    flex-direction: column;
    gap: var(--space-32);
    align-items: center;

    overflow: hidden;
    position: relative;
    inline-size: 100%;
}

    @media (min-width: 1120px) {.stats-section_stats__rel8z {
        --article-link-display: none
}
    }

    .stats-section_stats__rel8z .stats-section_static__Q_GJF {
        display: flex;
        inline-size: 100%;
        gap: var(--space-32);
        justify-content: center;
        align-items: baseline;
        padding: 0 var(--space-24);
    }

@media (min-width: 481px) {
        .stats-section_carousel__FwmD0::before,
        .stats-section_carousel__FwmD0::after {
            position: absolute;
            background: linear-gradient(270deg, #fefdfc 0%, rgba(254, 253, 252, 0) 100%);
            width: 100px;
            top: 0;
            bottom: 0;
            z-index: 1;
            content: "";
        }

        .stats-section_carousel__FwmD0::before {
            left: 0;
            transform: rotate(-180deg);
        }

        .stats-section_carousel__FwmD0::after {
            right: 0;
        }
    }

.stats-section_carousel__FwmD0 .stats-section_scroller__yL_gX {
        --duplicates: 3;
        --scroll-duration: 30s;
        --gap: var(--space-32);
        --offset: calc(var(--gap) / var(--duplicates));
        --move-initial: 0%;
        --move-final: calc(-1 * 100% / var(--duplicates) - var(--offset));

        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: var(--gap);
        inline-size: -moz-max-content;
        inline-size: max-content;
        animation: stats-section_marquee__O1W3L var(--scroll-duration) linear infinite;
    }

@keyframes stats-section_marquee__O1W3L {
            0% {
                transform: translateX(var(--move-initial));
            }

            100% {
                transform: translateX(var(--move-final));
            }
        }

@media (prefers-reduced-motion) {

.stats-section_carousel__FwmD0 .stats-section_scroller__yL_gX {
            --scroll-duration: 180s
    }
        }

.stats-section_copy__3as9x {
    text-align: var(--text-align);

    --text-lock-up-button-alignment: flex-start;
}

    .stats-section_copy__3as9x .stats-section_preheader__n2FrE {
        color: var(--display-onlight-color-red);
    }

    .stats-section_copy__3as9x .stats-section_description__k9hPK {
        color: var(--display-onlight-secondary);
    }

    @media (max-width: 1120px) {.stats-section_copy__3as9x {
        --text-align: center;
        --text-lock-up-button-alignment: unset
}
    }

    @media (max-width: 768px) {.stats-section_copy__3as9x {
        --text-align: left;
        --text-lock-up-button-alignment: flex-start
}
    }

.stats-section_copy__t7NvZ > div {
    block-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.customer-logos_customerLogosContainer__EHSxU {
    display: flex;
    flex-direction: column;
    align-items: var(--align-items, center);
    gap: var(--space-32);
    text-align: center;
}

    @media (max-width: 959px) {.customer-logos_customerLogosContainer__EHSxU {
        --align-items: flex-start
}
    }

    .customer-logos_customerLogosContainer__EHSxU img {
        max-block-size: var(--space-48);
        inline-size: auto;
    }

.prefooter_prefooter__RwRsA {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.prefooter_prefooterBackground__ErY_l {
    background: linear-gradient(
        0deg,
        var(--background-color, var(--composition-background-yellow)) 58%,
        transparent 100%
    );
    inline-size: 100%;
    padding-block-start: var(--space-192);
    padding-block-end: var(--space-128);
}

@media (max-width: 768px) {

.prefooter_prefooterBackground__ErY_l {
        padding-block-start: var(--space-128);
        padding-block-end: var(--space-96)
}
    }

.prefooter_textColumn__kt4Rx a {
    align-self: flex-start;
}

.prefooter_imageColumn__idQJt {
    inline-size: 100%;
    max-inline-size: 632px;
}

.prefooter_imageColumn__idQJt img {
        inline-size: 100%;
        block-size: auto;
    }

@media (max-width: 768px) {
    .prefooter_textColumn__kt4Rx {
        order: 1;
    }

    .prefooter_imageColumn__idQJt {
        order: 0;
    }
}

.two-column-section_textColumn__aglWF {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

    .two-column-section_textColumn__aglWF > div {
        max-inline-size: 400px;
    }

    .two-column-section_textColumn__aglWF a {
        align-self: flex-start;
    }

.two-column-section_sceneColumn__sovGB {
    max-inline-size: 590px;
}

.error-section_modal__W3bzb.error-section_modal__W3bzb {
    padding: var(--space-12) var(--space-12) var(--space-24);
    border-radius: var(--border-radius-xl);
}

    .error-section_modal__W3bzb.error-section_modal__W3bzb > div {
        padding: 0;
    }

.error-section_error__ez35O {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-32);
}

.error-section_error__ez35O .error-section_close__d_Vn_ {
        align-self: flex-end;
    }

.error-section_error__ez35O img {
        -o-object-fit: cover;
           object-fit: cover;
        margin-block-end: var(--space-32);
    }

.apps-section_heading__8Na6H {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.apps-section_galleryContainer__XQkwL {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-24);
}

.apps-section_imageContainer__EzMRC {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    margin: auto;
    aspect-ratio: 2 / 1;
    inline-size: 100%;
    border-radius: var(--border-radius-2xl);
    border: 0.5px solid var(--ui-border);
    overflow-y: hidden;
}

.apps-section_imageContainer__EzMRC > * {
        grid-column: 1;
        grid-row: 1;
    }

.apps-section_background__4_hsA {
    aspect-ratio: 2 / 1;
}

.apps-section_image__TdTg_ {
    inline-size: 100%;
    height: auto;
    aspect-ratio: 2 / 1;

    transition: opacity var(--animation-duration-600) var(--animation-ease-base);
    z-index: 2;
}

.apps-section_image__TdTg_.apps-section_active__hZ1fa {
        opacity: 1;
    }

.apps-section_image__TdTg_:not(.apps-section_active__hZ1fa) {
        opacity: 0;
    }

.apps-section_controlContainer__Vh3fy {
    text-align: center;
}

.apps-section_alertSection__RxyXw {
    text-align: center;
}

