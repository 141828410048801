.table {
    inline-size: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    position: relative;

    caption {
        padding: 0 var(--space-8) var(--space-12);

        @media (--screen-gt-lg) {
            position: absolute;
            transform: translateY(calc(-100% + 4px));
        }

        @media (--screen-lt-lg) {
            padding: 0 var(--space-8) 0;
        }
    }

    td,
    th {
        padding: 11px 8px;
    }

    th:first-child {
        width: 280px;
    }

    thead {
        th {
            @media (--screen-lt-lg) {
                padding: 16px 8px 12px;
            }
        }

        th:first-child {
            @media (--screen-lt-lg) {
                padding: 0;
                width: 0;
            }
        }

        th:last-child {
            width: 0;
            padding: 0;
        }
    }

    tbody {
        tr {
            block-size: 2.75rem;
            position: relative;
            transform-style: preserve-3d;

            @media (--screen-gt-lg) {
                &:not(.notHoverable):hover {
                    --feature-item-color: var(--display-onlight-primary);

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        background-color: var(--ui-background);
                        width: calc(100% + 8px);
                        height: calc(var(--height, 100%) - 10px);
                        left: 0;
                        top: 0;
                        transform: translateZ(-1px) translateY(5px) translateX(-4px);
                        border-radius: var(--border-radius-base);
                        pointer-events: none;
                    }
                }
            }
        }

        th,
        td {
            border-top: 1px solid var(--box-outline-border);
        }

        tr:last-child th,
        tr:last-child td {
            border-bottom: 1px solid var(--box-outline-border);
        }

        @media (--screen-lt-lg) {
            th {
                position: absolute;
                top: 0;
                border-top: none;
                border-bottom: none;
            }

            td {
                padding-block-start: 40px;
            }

            tr:last-child th {
                border-bottom: none;
            }
        }
    }
}

.icon {
    inline-size: 18px;
    block-size: 18px;
    vertical-align: top;
    margin-block-start: 2px;
}

.visuallyHidden:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.secondary {
    color: var(--display-onlight-secondary);
}

.tertiary {
    color: var(--display-onlight-tertiary);
}

.table.pricesTable {
    td {
        padding-block-start: 16px;
    }
}

.planNamesSticky {
    position: sticky;
    top: 72px;
    z-index: var(--z-index-sticky);
    background: var(--box-outline-background);
    border-bottom: 1px solid var(--box-outline-border);
    padding: var(--space-16) 0 var(--space-12) 278px;
    margin-bottom: var(--space-64);
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (--screen-lt-lg) {
        display: none;
    }
}

.plan {
    padding-inline: var(--space-8);
}

.buttonContainer {
    display: flex;
    padding-block-start: var(--space-32);

    a {
        flex: 1;
    }
}
