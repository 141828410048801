.pricingInfo {
    display: flex;
    align-items: center;
    gap: var(--space-8);
    min-block-size: 2.25rem;
    position: relative;

    @media (--screen-lt-lg) {
        align-items: flex-start;
    }
}

.priceGroup {
    display: flex;
    gap: var(--space-2);
}

.currency {
    color: var(--display-onlight-secondary);
    font-weight: var(--font-weight-regular);
    position: absolute;
    left: 0;
    transform: translateX(-100%);
}

.price {
    --min-font-size: 2;
    --max-font-size: 2;
    line-height: 1;
    letter-spacing: -0.02em;
    font-weight: var(--font-weight-regular);
}

.details {
    color: var(--display-onlight-tertiary);
}
