.planHeaderContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    margin-block-end: var(--space-24);
}

.contentContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);

    &.sm {
        --illustration-height: 75px;
    }

    @media (--screen-lt-md) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}

.pricingContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    margin-block-start: auto;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: var(--space-8);

    @media (--screen-lt-md) {
        justify-content: center;
    }
}

.additionalInfo {
    margin-block-end: calc(var(--space-12) * -1);
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

.planImage {
    block-size: var(--illustration-height, 100px);
    inline-size: auto;
    align-self: flex-start;
    object-fit: contain;
}
