.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--space-4);
    border-radius: var(--border-radius-sm);

    > svg {
        color: var(--display-onlight-tertiary);
        inline-size: var(--space-24);
        block-size: var(--space-24);
    }

    &.base {
        padding: 5px;

        > svg {
            inline-size: 18px;
            block-size: 18px;
        }
    }

    &.hovered {
        background-color: var(--actionable-quaternary-background-onhover);

        > svg {
            color: var(--display-onlight-primary);
        }
    }

    &.active {
        background-color: var(--productui-sidebar-actionable-quaternary-background-active);

        > svg {
            color: var(--display-onlight-primary);
        }
    }
}
