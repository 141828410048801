.header {
    display: flex;
    justify-content: center;
}

.grid {
    --min-item-size: 215px;

    display: grid;
    gap: var(--space-12);
    grid-template-columns: repeat(auto-fit, minmax(min(var(--min-item-size), 100%), 1fr));
    justify-content: center;
    inline-size: 100%;

    &:has(> :last-child:nth-child(4)) {
        @media (--screen-lt-lg) {
            /* force two columns on md screen */
            --min-item-size: 250px;
        }
    }
}

.wrapper {
    inline-size: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-24);
}

.accessoryView {
    color: var(--display-onlight-secondary);
    display: flex;
    align-items: center;
    gap: var(--space-4);

    > div {
        block-size: var(--space-24);
        inline-size: var(--space-24);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-block-start: 1px;

        svg {
            color: var(--display-onlight-tertiary);
        }
    }
}

.iconCard {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.link {
    outline: 0;

    &:hover {
        text-decoration: none;
    }

    &::after {
        position: absolute;
        content: "";
        inset: 0;
    }
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 11.5625rem;
    background-color: var(--bg-color, white);
    border-top-left-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);
}

.textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 4.125rem;
}
