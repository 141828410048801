.modal.modal {
    padding: var(--space-12) var(--space-12) var(--space-24);
    border-radius: var(--border-radius-xl);

    > div {
        padding: 0;
    }
}

.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--space-32);

    .close {
        align-self: flex-end;
    }

    img {
        object-fit: cover;
        margin-block-end: var(--space-32);
    }
}
