.taskContainer {
    position: relative;
}

.task {
    --task-background: var(--white);
    background-color: var(--task-background);
    width: 100%;
    padding: var(--space-8) 0;

    &.subtask {
        margin-inline-start: 36px;
    }
}

.board {
    padding: 14px 12px;
    border-radius: var(--border-radius-lg);
    border: 1px solid var(--card-border-idle);
    border-color: var(--card-border-color, var(--card-border-idle));
    background: var(--card-background, var(--card-background-idle));
    box-shadow: var(--card-box-shadow, var(--shadow-card-idle));

    .taskAttributeContainer {
        flex-direction: column;
        gap: var(--space-8);
    }

    &.hovered {
        --card-border-color: var(--card-border-onhover);
        --card-background: var(--card-background-onhover);
        --card-box-shadow: var(--shadow-card-onhover);
    }
}

.taskDescription {
    margin: 0 0 8px 36px;
    color: var(--display-onlight-secondary);

    &.checked {
        color: var(--display-onlight-tertiary);
    }
}

.taskOverflowContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    min-block-size: var(--tube-height-sm);

    & > * {
        grid-column: 1;
        grid-row: 1;
    }
}

.taskSection {
    display: flex;
    gap: var(--space-16);
    align-items: center;
}

.taskInfo {
    flex: 1;
    display: flex;
    gap: var(--space-12);
    align-items: center;
}

.taskCircle {
    flex: 0;
    display: flex;
    align-items: center;
    align-self: baseline;
    block-size: calc(16px * var(--body-sm-line-height));
}

.taskContent {
    flex: 1 1 0;
}

.projectSection {
    flex: 0 1 var(--basis, 20%);
    display: flex;
    align-items: center;
    gap: var(--space-4);
}

.projectIcon {
    color: var(--productui-primary-theme-color);
    flex-shrink: 0;
}

.taskText {
    color: var(--display-onlight-primary);

    &.checked {
        color: var(--display-onlight-tertiary);
        text-decoration: line-through;
        text-decoration-color: var(--ui-border);
    }
}

.attributeButtonContainer {
    display: flex;
    inline-size: 100%;
    background-color: var(--task-background);
    inline-size: min-content;
    align-self: center;
    justify-self: end;
}

.taskAttributeContainer {
    display: flex;
    gap: var(--space-8);
    margin-inline-start: 36px;
    padding-block-start: var(--space-4);
}

.taskAttributeRow {
    display: flex;
    gap: var(--space-8);
    align-items: center;
}

.taskAttribute {
    display: flex;
    align-items: center;
    gap: var(--space-4);
    color: var(--display-onlight-tertiary);
}

.taskAttributeIcon {
    flex-shrink: 0;
    flex-grow: 0;
}

.dueDateTomorrow {
    color: var(--display-onlight-color-orange);
}

.dueDateToday {
    color: var(--display-onlight-color-green);
}

.repeat {
    color: var(--display-onlight-color-green);
}

.contextMenu {
    position: absolute;
    top: var(--context-menu-y, 0);
    right: var(--context-menu-x, 0);
    min-inline-size: 10.625rem; /* 170px */
}
