.quickAddWrapper {
    --product-ui-display-onlight-skeleton: var(--productui-display-skeleton-color);
    --left-right-gap: 6px;
    --list-item-background-hover: var(--productui-sidebar-actionable-quaternary-background-active);

    p {
        color: var(--productui-primary-theme-color);
    }
}

.quickAddIcon {
    color: var(--productui-primary-theme-color);
}
