.weeklyCalendarDay {
    position: relative;
    block-size: calc(24 * var(--hour-height));
    border-left: 1px solid var(--product-ui-divider-secondary);

    display: flex;
    flex-direction: column;

    .hour {
        border-top: 1px solid var(--product-ui-divider-secondary);
        inline-size: 100%;
        block-size: var(--hour-height);
    }
}

.weeklyCalendarDayTimes {
    display: flex;
    flex-direction: column;
    position: relative;

    .hour {
        inline-size: 100%;
        padding-inline-end: var(--space-8);
        block-size: var(--hour-height);

        .legend {
            margin-block-start: calc(-1 * var(--space-8) - 1px);
        }
    }
}

.weeklyCalendarDayDates {
    block-size: 28px;
    inline-size: calc(100% - var(--space-64));
    margin-inline-start: var(--space-64);
    display: grid;
    grid-template-columns: repeat(var(--day-count), 1fr);

    .date {
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            margin-inline-start: 3px;
            display: flex;
            justify-content: center;
        }

        &.selected {
            color: var(--display-onlight-primary);
            font-weight: 500;

            > span {
                background-color: var(--productui-primary-theme-color);
                color: white;
                border-radius: var(--border-radius-sm);
                padding-inline: var(--space-4);
                min-inline-size: 20px;
                block-size: 20px;
                display: flex;
                align-items: center;
            }
        }
    }
}

.legend {
    color: var(--display-onlight-secondary);
    text-align: right;
    font-weight: 400;
}

.weeklyCalendarViewFold {
    --fold-hour: 0;

    margin-block-start: -9px;
    position: absolute;
    top: calc(var(--fold-hour) * var(--hour-height));
    left: 0;
    inline-size: 100%;
    block-size: 18px;

    display: grid;
    grid-template-columns: 66px 1fr;
    align-items: center;

    .time {
        text-align: right;
        margin-inline-start: calc(-1 * var(--space-12));
        background-color: white;
        > p {
            color: var(--productui-primary-theme-color);
            margin-inline-end: var(--space-8);
        }
        position: relative;
        &::before {
            position: absolute;
            content: "";
            top: calc(-1 * var(--space-8));
            left: 0;
            right: 0;
            block-size: var(--space-8);
            background: linear-gradient(transparent, white 50%);
        }
        &::after {
            position: absolute;
            content: "";
            top: 100%;
            left: 0;
            right: 0;
            block-size: var(--space-8);
            background: linear-gradient(white 50%, transparent);
        }
    }

    .fold {
        block-size: 100%;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            block-size: 1px;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--productui-primary-theme-color);
            opacity: 0.5;
        }

        .highlightedCircle {
            position: absolute;
            left: calc(100% / var(--day-count) * var(--current-day-position) - 5px);
            top: 50%;
            transform: translateY(-50%);
            border-radius: 100%;
            inline-size: 11px;
            block-size: 11px;
            background-color: var(--productui-primary-theme-color);
            border: 1px solid white;
        }

        .highlightedFold {
            position: absolute;
            left: calc(100% / var(--day-count) * var(--current-day-position));
            inline-size: calc(100% / var(--day-count));
            block-size: 2px;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--productui-primary-theme-color);
        }
    }
}

.weeklyCalendarAllDays {
    min-block-size: var(--space-32);
    border-top: 1px solid var(--product-ui-divider-secondary);
    border-bottom: 1px solid var(--product-ui-divider-secondary);
    inline-size: 100%;
    display: grid;
    grid-template-columns: 66px repeat(var(--day-count), 1fr);

    .allDayLegend {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        padding-inline-end: var(--space-8);

        p {
            font-weight: 400;
            padding-block-start: var(--space-2);
        }
    }

    > div {
        &:not(:first-child) {
            border-left: 1px solid var(--product-ui-divider-secondary);
        }
        block-size: 100%;
        display: flex;
        flex-direction: column;
        padding-block: var(--space-2);
        padding-inline-start: var(--space-2);
        gap: var(--space-2);
    }
}
