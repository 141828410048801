.header {
    display: grid;
}

.top {
    block-size: 56px;
    padding: 0 var(--space-12) 0 var(--space-8);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.bottom {
    padding: var(--space-16) var(--space-48) var(--space-32);
    margin: 0 auto;
    inline-size: 100%;
    max-inline-size: var(--container-width-xs, 698px);

    &.fullWidth {
        max-inline-size: 100%;
    }

    &.noBottomPadding {
        padding-block-end: 0;
    }

    &.minPadding {
        padding-inline: var(--space-12);
    }
}

.breadcrumbsWrapper {
    display: flex;
    align-items: center;
}

.breadcrumbs {
    display: flex;
    align-items: center;
    padding-inline-start: var(--space-4);
}

.breadcrumbsSeparator {
    margin-inline: var(--space-4);
    color: var(--display-onlight-tertiary);
}

.actions {
    display: flex;
    gap: var(--space-12);
}

.title {
    display: flex;
    gap: var(--space-12);
    align-items: center;
}

.calendarControls {
    margin-block-start: var(--space-8);
    margin-block-end: var(--space-16);
    color: var(--display-onlight-secondary);
}

.calendarControlsDefault {
    display: flex;
    justify-content: flex-end;
    gap: var(--space-12);
}

.calendarControlsSubtitle {
    display: flex;
    gap: 2px;
    margin-inline-end: auto;
    align-items: center;
}

.todayViewSubtitle {
    display: flex;
    gap: var(--space-12);
    margin-block-start: var(--space-8);
}

.todayViewItem {
    display: flex;
    gap: var(--space-4);
    align-items: center;
    color: var(--display-onlight-secondary);
}

.previewBanner {
    display: flex;
    min-height: var(--tube-height-base);
    padding: 0px var(--space-12);
    align-items: center;
    gap: var(--space-12);
    background-color: var(--productui-themed-box-background);
    border-radius: var(--border-radius-lg);
}

.previewBannerIcon {
    flex: 0 0 var(--icon-xl);
    block-size: var(--icon-xl);
    color: var(--display-onlight-tertiary);
}

.previewBannerContent {
    display: flex;
    align-items: center;
    gap: var(--space-8);
    flex: 1;
}

.previewBannerText {
    color: var(--display-onlight-primary);
}
