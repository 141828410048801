.outlineButtonGroup {
    display: flex;
    align-items: center;
    block-size: var(--tube-height-xs);
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--ui-border);
    overflow: hidden;

    .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        block-size: 26px;
        inline-size: 26px;
        border-radius: var(--border-radius-sm);

        &.hovered {
            background-color: var(--actionable-quaternary-background-onhover);
        }
    }

    .separator {
        block-size: calc(100% - var(--space-8));
        inline-size: 1px;
        background-color: var(--ui-border);
    }

    .buttonWrapper {
        padding: 0 9px;
    }
}
