.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    padding-block-start: var(--space-32);
    padding-block-end: var(--space-32);
    position: relative;

    @media (--screen-lt-md) {
        padding-block-end: var(--space-16);
    }

    h1 {
        color: var(--display-onlight-primary);

        @media (--screen-lt-md) {
            br {
                display: none;
            }
        }
    }

    p {
        max-inline-size: 650px;
        color: var(--display-onlight-secondary);
    }
}
