.weeklyCalendarTask {
    /* Height is based on the duration of the task. */
    --weekly-calendar-height: 50px;
    block-size: var(--weekly-calendar-height);

    --priority-bg-color: var(--product-ui-background-priority-4);
    background-color: oklch(from var(--priority-bg-color) l c h / 60%);

    inline-size: calc(100% - var(--space-4));
    border-radius: 5px;
    padding: 2px var(--space-4);
    display: flex;
    gap: var(--space-4);
    min-block-size: 20px;

    .taskCircle {
        inline-size: var(--space-12);
        block-size: var(--space-16);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        min-inline-size: 0;

        > div:first-child {
            flex-shrink: 0;
            flex-grow: 0;
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: var(--space-2);
        max-inline-size: calc(100% - var(--space-16));

        .text {
            text-align: left;
            color: var(--display-onlight-primary);
            line-height: 1.3;
            max-inline-size: calc(100% - var(--space-4));
        }

        .repeat {
            color: var(--display-onlight-secondary);
            line-height: 1.3;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: var(--space-2);
            flex-shrink: 0;
        }

        > svg {
            flex-shrink: 0;
        }
    }

    /* If the duration is less than an hour, show the task title on one line. */
    @media (--screen-lt-md) {
        container-name: compact;
        container-type: inline-size;
    }
    &.compact {
        container-name: compact;
        container-type: inline-size;
    }
    @container compact (min-width: 0px) {
        .right {
            max-inline-size: calc(100% - var(--space-16));

            .text {
                flex-wrap: nowrap;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                block-size: var(--space-16);
            }

            &.withRepeat {
                flex-direction: row;
                align-items: center;
                block-size: var(--space-16);
                p {
                    max-inline-size: 124px;
                }
                svg {
                    color: var(--display-onlight-secondary);
                    block-size: var(--space-12);
                }
            }
        }
    }

    /* The task is meant to be displayed on a calendar day. */
    --weekly-calendar-start-time-offset: 0px;
    &.inContext {
        position: absolute;
        top: var(--weekly-calendar-start-time-offset);
    }

    /* Compute background color based on priority */
    &.p4 {
        --priority-color: var(--product-ui-priority-4);
        --priority-bg-color: var(--product-ui-background-priority-4);
    }
    &.p3 {
        --priority-color: var(--product-ui-priority-3);
        --priority-bg-color: var(--product-ui-background-priority-3);
    }
    &.p2 {
        --priority-color: var(--product-ui-priority-2);
        --priority-bg-color: var(--product-ui-background-priority-2);
    }
    &.p1 {
        --priority-color: var(--product-ui-priority-1);
        --priority-bg-color: var(--product-ui-background-priority-1);
    }

    /* Uncompletable tasks shows differently */
    &.notCompletable {
        background-color: white;
        border: 1px solid var(--product-ui-inputs-border-idle);

        .right {
            max-inline-size: calc(100% - var(--space-8));
        }

        .taskCircle {
            inline-size: 5px;
            block-size: 100%;
            position: relative;

            &:after {
                position: absolute;
                content: "";
                border-radius: var(--space-2);
                top: 0;
                left: 0;
                right: var(--space-2);
                bottom: 0;
                background-color: var(--priority-color);
            }
        }
    }

    &.dragging {
        opacity: 0.7;
    }
}
