.boxShadow {
    box-shadow: var(--box-shadow, var(--shadow-productui-app-window));
    mix-blend-mode: multiply;
    border-radius: var(--border-radius-xl);
    pointer-events: none;

    position: absolute;
    inset: 0;
}

.red {
    --box-shadow: var(--shadow-elevated-red);
}

.green {
    --box-shadow: var(--shadow-elevated-green);
}

.yellow {
    --box-shadow: var(--shadow-elevated-yellow);
}

.seaSalt {
    --box-shadow: var(--shadow-elevated-sea-salt);
}
