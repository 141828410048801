.prefooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.prefooterBackground {
    background: linear-gradient(
        0deg,
        var(--background-color, var(--composition-background-yellow)) 58%,
        transparent 100%
    );
    inline-size: 100%;
    padding-block-start: var(--space-192);
    padding-block-end: var(--space-128);

    @media (--screen-lt-md) {
        padding-block-start: var(--space-128);
        padding-block-end: var(--space-96);
    }
}

.textColumn a {
    align-self: flex-start;
}

.imageColumn {
    inline-size: 100%;
    max-inline-size: 632px;

    img {
        inline-size: 100%;
        block-size: auto;
    }
}

@media (--screen-lt-md) {
    .textColumn {
        order: 1;
    }

    .imageColumn {
        order: 0;
    }
}
