.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--space-12);
}

.iconCard {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
    inline-size: 100%;
    max-inline-size: var(--max-size, 230px);

    @media (--screen-lt-md) {
        --max-size: 100%;
    }

    @media (--screen-gt-xlg) {
        --max-size: 352px;
    }
}

.link {
    outline: 0;

    &:hover {
        text-decoration: none;
    }

    &::after {
        position: absolute;
        content: "";
        inset: 0;
    }
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 11.5625rem;
    background-color: var(--bg-color, white);
    border-top-left-radius: var(--card-border-radius);
    border-top-right-radius: var(--card-border-radius);
}

.textContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    inline-size: 100%;
    block-size: 4.125rem;
}

.sectionHeader {
    margin-inline: auto;
}
