.backgroundWrapper {
    background: linear-gradient(
        var(--composition-background-neutral) 0,
        var(--composition-background-red) 58%
    );
}

.subtitle {
    color: var(--display-onlight-secondary);
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.awardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    gap: var(--gap, var(--space-64));

    @media (--screen-lt-md) {
        --gap: var(--space-32);
    }

    @media (--screen-lt-sm) {
        --gap: var(--space-16);
    }
}

.awardMiddle {
    @media (--screen-gt-md) {
        padding-block-end: var(--space-24);
    }
}
