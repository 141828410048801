.sequenceContainer
    *:where([data-productuiview="personal-workspace"], [data-productuiview="team-workspace"]) {
    display: none;
}

.sequenceContainer [data-productui="sidebarsection"] {
    overflow: hidden;
    block-size: 0;
}

.sequenceContainer [data-productui="cursor"] {
    inset: 50%;
    opacity: 0;
}
