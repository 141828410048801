.weeklyCalendarAllDayInfo {
    display: flex;
    align-items: center;
    padding-inline-start: var(--space-4);
    max-inline-size: calc(100% - var(--space-4));
    block-size: 20px;
    border-radius: 5px;
    border: 1px solid transparent;
    color: var(--display-onlight-secondary);

    > p {
        padding-inline: 1px;
        max-inline-size: 100%;
        block-size: 100%;
        flex-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.6;
    }

    &.withTaskCollapseIcon {
        border-color: var(--product-ui-inputs-border-idle);
        p {
            padding-inline: 0;
        }

        > svg {
            flex-shrink: 0;
            min-inline-size: 0;
            margin-inline-end: var(--space-4);
        }

        @media (--screen-lt-sm) {
            > svg {
                display: none;
            }
        }
    }
}
