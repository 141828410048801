.textLockUp {
    display: flex;
    flex-direction: column;

    .textContainer {
        display: flex;
        flex-direction: column;
    }

    .buttonRow {
        display: flex;
        flex-direction: column;
        align-items: var(--text-lock-up-button-alignment, inherit);
    }

    .body {
        color: var(--display-onlight-secondary);
    }

    .preheader {
        color: var(--text-lock-up-preheader-color, var(--display-onlight-color-red));
    }

    &.lg {
        gap: var(--container-gap, var(--space-32));

        .textContainer {
            gap: var(--text-gap, var(--space-24));
        }

        .buttonRow {
            gap: var(--space-12);
        }
    }

    &.base {
        gap: var(--space-24);

        .textContainer {
            gap: var(--space-16);
        }

        .buttonRow {
            gap: 14px;
        }
    }

    @media (--screen-lt-md) {
        &.lg {
            --container-gap: var(--space-24);

            .textContainer {
                --text-gap: var(--space-16);
            }
        }
    }
}
