.windowWrapper {
    position: relative;
    inline-size: 100%;
    block-size: 100%;
}

.windowFrame {
    --sidebar-inline-size: 15.625rem; /* 250px */
    --topbar-block-size: 2.625rem; /* 42px */

    inline-size: var(--window-inline-size, 100%);
    block-size: var(--window-block-size, 100%);
    background-color: var(--elevated-background);
    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-base);

    position: relative;
    display: grid;
    grid-template-columns: [full-start sidebar-start] var(--sidebar-inline-size) [sidebar-end view-start] 1fr [view-end full-end];
    grid-template-rows: [full-start topbar-start] var(--topbar-block-size) [topbar-end view-start] 1fr [view-end full-end];
}

.overlay {
    inline-size: var(--window-inline-size, 100%);
    block-size: var(--window-block-size, 100%);

    &::after {
        border-radius: var(--border-radius-base);
    }
}

.views {
    display: grid;
    inline-size: 100%;
    block-size: 100%;
    border-radius: inherit;
    overflow: hidden;
    grid-column: view;
    grid-row: topbar-start / view-end;

    & > * {
        grid-area: 1 / 1;
    }
}

.view {
    inline-size: 100%;
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}

.topbar {
    border-top-right-radius: inherit;
    border-top-left-radius: inherit;
    grid-column: sidebar-start / view-end;
}

.sidebar {
    grid-column: sidebar;
    grid-row: topbar-start / view-end;
}

.rightSidePanel {
    grid-column: rightsidebar;
    grid-row: topbar-start / view-end;
}

.modal {
    border-radius: inherit;
    grid-area: full;
    display: grid;
    grid-template-columns:
        [full-start] var(--space-24) [modal-start] 1fr [modal-end] var(--space-24)
        [full-end];
    grid-template-rows:
        [full-start] var(--space-24) [modal-start] 1fr [modal-end] var(--space-24)
        [full-end];
    backdrop-filter: blur(4px);
    background-color: var(--overlay-background-idle);
    z-index: var(--product-ui-z-index-popup);

    & > * {
        grid-area: modal;
    }
}

.hideSidebar {
    .sidebar {
        transform: translateX(calc(--sidebar-inline-size * -1));
    }

    .views {
        grid-column: sidebar-start / view-end;
        margin-inline: auto;
    }
}
