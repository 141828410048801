.layout {
    inline-size: 100%;
}

.header {
    display: flex;
    justify-content: center;
}

.illustrationContainer {
    margin-inline: auto;
    inline-size: calc(var(--vw, 1vw) * 100);
    max-inline-size: 680px;
    overflow: hidden;
}

.illustration {
    inline-size: 100%;
    min-inline-size: 450px;
    block-size: auto;
    margin-inline-start: 50%;
    transform: translateX(-50%);
}

.alertSection {
    text-align: center;
}
