.featureItem {
    --icon-size: var(--icon-base); /* 18px */

    color: var(--feature-item-color, var(--display-onlight-secondary));
    min-block-size: 22px;

    .tooltip &:hover {
        cursor: help;
    }

    &.base {
        --icon-size: var(--icon-lg); /* 20px */
    }

    svg {
        margin-block-start: 3px;
        flex: 0 0 var(--icon-size);
        height: var(--icon-size);
        vertical-align: top;
        min-inline-size: var(--icon-size);
    }
}

.icon {
    color: var(--display-onlight-tertiary);
    margin-inline-end: var(--space-8);

    &.green {
        color: var(--display-onlight-color-green);
    }

    &.yellow {
        color: var(--display-onlight-color-yellow);
    }
}

.toggle {
    color: var(--display-onlight-tertiary);
    border-radius: var(--border-radius-full);
    margin-inline-start: var(--space-8);
}

.tooltip {
    inline-size: 100%;

    &:not(:has(.toggle)) {
        &:focus-visible {
            border-radius: var(--border-radius-sm);
            box-shadow: var(--shadow-focus);
        }
    }

    &:focus-visible {
        outline: none;

        .toggle {
            box-shadow: var(--shadow-focus);
        }
    }
}

.badge {
    display: inline-block;
    position: relative;
    bottom: 1px;
}
