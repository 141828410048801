.categorySection {
    align-items: center;
    display: flex;
    flex-direction: column;

    .gridContainer {
        display: grid;
        gap: var(--space-12);
        grid-template-columns: repeat(auto-fit, var(--grid-item-size, 155px));
        justify-content: center;
        margin-block-end: var(--space-24);
        inline-size: 100%;

        @media (--screen-gt-md) {
            --grid-item-size: 191px;
        }
    }
}
