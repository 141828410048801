.prefooterBackground {
    background: linear-gradient(
        0deg,
        var(--background-color, --composition-background-yellow) 58%,
        transparent 100%
    );
    inline-size: 100%;
    padding-block-start: var(--space-192);
}

.leftColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--space-32);

    img {
        inline-size: 100%;
        max-inline-size: 372px;
        block-size: auto;

        @media (--screen-lt-md) {
            width: 226px;
        }
    }
}

.rightColumn {
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--screen-lt-md) {
        text-align: center;
    }
}
