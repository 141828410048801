.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    inline-size: 100%;

    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}

.horizontalScroll {
    display: flex;
    gap: var(--space-12);
    justify-content: center;

    max-inline-size: 1088px;
    inline-size: 100%;

    overflow-x: auto;
    scroll-snap-type: x mandatory;

    @media (--screen-lt-lg) {
        justify-content: flex-start;
    }

    @media (--screen-lt-lg) {
        /* center the last blog card in the horizontal scroll */
        &::after {
            content: "";
            flex: 0 0 50%;
        }
    }
}

.column {
    display: flex;
    gap: var(--space-12);
    scroll-snap-align: center;
    min-inline-size: 295px;
    max-inline-size: 347px;

    article {
        block-size: 100%;

        & > img {
            min-block-size: var(--img-size, 156px);
        }
    }

    @media (--screen-gt-md) {
        --img-size: 185px;
    }
}
