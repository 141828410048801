.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-32);
    padding-block: var(--space-96);
}

.tabBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    inline-size: 100%;
    margin-block-start: var(--space-4);
    border-bottom: 1px solid var(--elevated-border);
}

.selectField {
    margin-block-end: var(--space-24);

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.tab {
    padding: var(--space-8) var(--space-12);
    text-decoration: none;
    margin-bottom: -1px;
    text-align: center;

    p {
        color: var(--display-onlight-tertiary);
    }

    a {
        text-decoration: none;
    }
}

.selected {
    border-bottom: 1px solid var(--display-onlight-primary);

    p {
        color: var(--display-onlight-primary);
    }
}

.StoryContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-12);
    position: relative;

    inline-size: 100%;

    padding: var(--space-24);
    padding-block-start: var(--space-32);
    margin-block-end: var(--space-24);

    background-color: var(--composition-background-neutral);
    border: 1px dashed var(--elevated-border);
    border-radius: var(--border-radius-xl);
    background-color: #fff;

    .badge {
        position: absolute;
        top: -13px;
        left: 24px;

        span {
            font-family: monospace;
            font-weight: 600;
        }
    }
}

.story {
    display: flex;
    flex-direction: column;
    gap: var(--space-12);
    padding: var(--space-8) var(--space-12);
    border: 1px dashed var(--elevated-border);
    border-radius: var(--border-radius-base);
    background-color: #fff;
    inline-size: 100%;

    .name {
        align-self: flex-start;
        color: var(--display-onlight-tertiary);
    }

    .components {
        display: flex;
        gap: var(--space-12);

        &.vertical {
            flex-direction: column;
        }
    }
}

.boardView {
    gap: 12px;
    display: flex;
    align-items: flex-start;
    gap: var(--space-12);
    flex: 1 1 0;

    > * {
        flex: 1 1 0;
    }
}

.column {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1 0;
}

.scenes > div {
    > h2 {
        margin-block-end: var(--space-16);

        &:not(:first-child) {
            margin-block-start: var(--space-64);
        }
    }

    > ul {
        margin-block-end: var(--space-32);

        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--space-24);

        > li {
            margin-block-start: var(--space-16);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3 {
                margin-block-end: var(--space-16);
            }
        }

        &.views {
            grid-template-columns: 1fr;
        }
    }
}

.themeContainer {
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: var(--composition-background-neutral);
}
