.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: var(--space-24);

    p {
        color: var(--display-onlight-secondary);
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--space-12);
    }
}

.background {
    background: linear-gradient(0deg, var(--composition-background-red) 58%, transparent 100%);
}
