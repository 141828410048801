.taskBackground {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.hoverIncentive {
    max-inline-size: 200px;
    position: absolute;
    top: 30px;
    right: -120px;

    @media (max-width: 1350px) {
        position: relative;
        top: 0;
        max-inline-size: 230px;
        right: 0;
        left: 100%;
        transform: translateX(-110%) translateY(-24px);

        svg {
            transform: rotate(-80deg) translateY(16px);
        }
    }
}

.relative {
    position: relative;
}
