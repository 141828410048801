.wrapper {
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: var(--bg-position, top center);
    inline-size: 100%;
    margin-block-end: calc(var(--space-96) * -1);

    @media (--screen-lt-md) {
        --bg-position: center;
    }
}

.container {
    padding-block-end: var(--space-96);
}

.top {
    display: flex;
    flex-direction: var(--top-direction, row);
    gap: var(--top-gap, var(--space-24));
    align-items: center;

    @media (--screen-lt-md) {
        --top-direction: column;
        --top-gap: var(--space-32);
    }
}

.left {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    min-inline-size: var(--min-width, 300px);

    .subtitle {
        display: flex;
        gap: var(--space-8);
        align-items: center;
        color: var(--display-onlight-secondary);
    }

    @media (--screen-lt-md) {
        --min-width: 100%;
    }
}

.right {
    display: flex;
    inline-size: 100%;

    @media (--screen-gt-md) {
        justify-content: center;
    }
}

.illustration {
    margin-block-start: calc(var(--space-16) * -1);
}

.divider {
    margin-block-start: var(--divider-margin, var(--space-24));
    inline-size: var(--divider-width, calc(100% + var(--space-48)));

    @media (--screen-lt-md) {
        --divider-margin: 0;
        --divider-width: 100%;
    }
}

.pricingBox {
    display: flex;
    flex-direction: column;
    gap: var(--box-gap, var(--space-24));
    align-items: center;

    max-width: 800px;
    padding: var(
        --box-padding,
        var(--space-48) calc(var(--space-48) + var(--space-24)) var(--space-24)
    );
    margin: 0 auto;

    border: 1px solid var(--elevated-border);
    border-radius: var(--border-radius-xl);

    background-color: var(--elevated-background);
    box-shadow: var(--shadow-elevated);

    @media (--screen-lt-md) {
        --box-gap: var(--space-32);
        --box-padding: var(--space-32) var(--space-24) var(--space-24);
    }
}

.buttonRow {
    display: flex;
    flex-direction: var(--button-row-direction, row);
    inline-size: 100%;
    gap: var(--space-12);

    a {
        inline-size: 100%;
    }

    @media (--screen-lt-md) {
        --button-row-direction: column;
        margin-block-end: calc(var(--space-16) * -1);
    }
}

.featureItems {
    display: flex;
    flex-direction: column;
    gap: var(--space-16);
}

.featureItem {
    display: flex;
    align-items: center;
    gap: var(--space-12);

    color: var(--display-onlight-secondary);
}

.handwrittenBoxWrapper {
    position: relative;
    inline-size: 100%;
    max-inline-size: 800px;
    margin: 0 auto;
}

.handwrittenBox {
    position: absolute;
    top: var(--handwrittenBoxTop, 40px);
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    max-inline-size: 359px;
    inline-size: 100%;

    p {
        position: absolute;
        top: 50px;

        background-color: var(--composition-background-neutral);
        inline-size: var(--text-width, 335px);
    }

    svg {
        height: var(--arrowHeight, 313px);
    }

    @media (--screen-lt-md) {
        --handwrittenBoxTop: 24px;

        p {
            --text-width: 286px;
        }
        svg {
            --arrowHeight: 248px;
        }
    }
}
