.sceneContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    block-size: 100%;
    inline-size: 100%;
    border-radius: inherit;

    & > * {
        grid-row: 1;
        grid-column: 1;
    }
}

.sceneLayer {
    display: grid;
    inline-size: 100%;
    block-size: 100%;
    overflow: hidden;
    z-index: 1;

    & > * {
        grid-column: content;
        grid-row: content;
    }
}

.bottom,
.bottomCenter {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end] var(
            --product-ui-frame-inset-base
        ) [full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
}

.bottomCenter {
    justify-items: center;
}

.bottomRight {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
}

.bottomLeft {
    grid-template-columns: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-base
        ) [full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
    justify-content: end;
}

.bottomOverlay {
    grid-template-rows: [full-start] var(--product-ui-frame-inset-window-modal-open) [content-start] 1fr [content-end full-end];
    grid-template-columns: [full-start content-start] 1fr [content-end full-end];
    justify-content: center;
}

.modalOpenLeft {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-window-modal-open) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
}

.modalOpenTopRight {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-window-overlay-view-open) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-window-overlay-view-open
        ) [full-end];
    align-content: end;

    .sceneContainer:has(&) {
        align-content: end;
    }
}

.modalOpenBottomRight {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-window-overlay-view-open) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start] var(--product-ui-frame-inset-window-overlay-view-open) [content-start] 1fr [content-end full-end];
}

.modalOpenCenterLeft {
    grid-template-columns: [full-start content-start] 1fr [content-end] var(--space-128) [full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(--space-32) [full-end];
    align-content: end;
    justify-content: end;

    .sceneContainer:has(&) {
        align-content: end;
    }
}

.modalOpenTopLeft {
    grid-template-columns: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-window-overlay-view-open
        ) [full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-window-overlay-view-open
        ) [full-end];
    align-content: end;
    justify-content: end;

    .sceneContainer:has(&) {
        align-content: end;
    }
}

.center {
    grid-template-columns:
        [full-start] var(--product-ui-frame-inset-overlay-view-base)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];
    grid-template-rows:
        [full-start] var(--product-ui-frame-inset-overlay-view-base)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];

    align-items: center;
}

.centerTop {
    grid-template-columns:
        [full-start] var(--product-ui-frame-inset-overlay-view-base)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];
    grid-template-rows:
        [full-start] var(--product-ui-frame-inset-overlay-view-top)
        [content-start] 1fr [content-end] var(--product-ui-frame-inset-overlay-view-base)
        [full-end];

    align-items: flex-start;
}

.topRight {
    grid-template-columns: [full-start] var(--product-ui-frame-inset-base) [content-start] 1fr [content-end full-end];
    grid-template-rows: [full-start content-start] 1fr [content-end] var(
            --product-ui-frame-inset-base
        ) [full-end];
    align-content: end;
}
