.taskCircle {
    --task-circle-border: var(--display-onlight-tertiary);
    --task-circle-size: 24px;
    --circle-border-width: 2px;
    --circle-pattern: solid;

    display: flex;
    align-items: center;
    inline-size: var(--task-circle-size);
    block-size: var(--task-circle-size);
    background-color: var(--task-circle-background, transparent);
    border: var(--circle-border-width) var(--circle-pattern) var(--task-circle-border);
    border-radius: var(--border-radius-full);
    color: var(--task-circle-border);

    &.checked {
        --task-circle-background: var(--task-circle-border);
        color: #fff;
    }

    &.calendar {
        --task-circle-size: 12px;
        --circle-border-width: 1.5px;

        &.futureOccurrence {
            --circle-pattern: dashed;
        }
    }

    &.subtitle {
        --task-circle-size: 20px;
    }
}

.p4 {
    --circle-border-width: 1px;
    &.calendar {
        --circle-border-width: 1px;
    }
    /* We have to flip it here because of the transparency of onlight tertiary, 
        otherwise the border appears darker as it's two layers of the colour */
    &.checked {
        --task-circle-border: transparent;
        --task-circle-background: var(--display-onlight-tertiary);
    }
}

.p3 {
    --task-circle-border: var(--productui-meta-blue);
    --task-circle-background: rgb(var(--productui-meta-blue-rgb) / 0.1);
}

.p2 {
    --task-circle-border: var(--productui-meta-orange);
    --task-circle-background: rgb(var(--productui-meta-orange-rgb) / 0.1);
}

.p1 {
    --task-circle-border: var(--productui-meta-red);
    --task-circle-background: rgb(var(--productui-meta-red-rgb) / 0.1);
}
