.buttonWithPricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-16);

    @media (--screen-lt-lg) {
        flex-direction: column;
    }

    sup {
        vertical-align: top;
        margin-top: 1px;
    }
}

.onlightSecondary {
    color: var(--display-onlight-secondary);
}
