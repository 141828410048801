.customerLogosContainer {
    display: flex;
    flex-direction: column;
    align-items: var(--align-items, center);
    gap: var(--space-32);
    text-align: center;

    @media (--screen-lt-lg) {
        --align-items: flex-start;
    }

    img {
        max-block-size: var(--space-48);
        inline-size: auto;
    }
}
