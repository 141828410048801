.rightSidePanel {
    inline-size: 250px;
    block-size: 100%;
    border-left: 1px solid var(--ui-border);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    block-size: 56px;
    border-bottom: 1px solid var(--ui-border);
    padding: var(--space-12);
}

.item {
    margin: 0 var(--space-12);
    border-bottom: 1px solid var(--ui-border);
}
