.showCaseContainer {
    --padding: 2.5rem;

    display: flex;
    flex-direction: column;
    gap: var(--showCaseGap, var(--space-96));

    @media (--screen-lt-md) {
        --showCaseGap: var(--space-64);
    }

    @media (--screen-lt-xlg) {
        --padding: 1.5rem;
    }
}

.personalPlansContainer {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    gap: 0;
    background: var(--box-outline-background);
    border: 1px solid var(--box-outline-border);
    border-radius: var(--border-radius-lg);

    @media (--screen-lt-md) {
        grid-auto-flow: row;
    }
}

.column {
    position: relative;
    padding: 36px var(--space-48);

    &:not(:last-child) {
        border-right: 1px solid var(--box-outline-border);
    }

    @media (--screen-gt-md) {
        display: grid;
        grid-row: 1 / 3;
        grid-template-rows: subgrid;
    }

    .badge {
        position: absolute;
        top: var(--space-8);
        right: var(--space-8);
    }

    @media (--screen-lt-md) {
        padding: var(--space-24) var(--space-32);

        &:not(:last-child) {
            border-right: none;
        }

        .badge {
            display: none;
        }
    }
}

.benefitsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .buttonContainer {
        display: flex;
        padding-block-start: var(--space-32);

        a {
            flex: 1;
        }
    }
}

.workspaceInfo {
    flex: 1;

    @media (--screen-gt-xlg) {
        border-right: 1px solid var(--ui-border);
        padding-inline-end: var(--padding);
    }

    @media (--screen-lt-xlg) {
        margin-block-end: var(--space-32);
    }

    @media (--screen-lt-lg) {
        margin-block-end: var(--space-16);
    }
}

.plan {
    display: flex;
    flex-direction: column;
    gap: var(--space-24);
    flex: 1;
}

.colorRed {
    color: var(--display-onlight-color-red);
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}

.designedForTeams {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(85px, -60px);
    flex-direction: column;
    max-inline-size: 170px;

    > div {
        align-self: center;
    }

    svg {
        transform: translateX(-30px);
    }
}
