.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.grid {
    display: flex;
    justify-content: center;
    inline-size: 100%;

    ul {
        display: flex;
        gap: var(--space-12);
        justify-content: center;
        inline-size: 100%;

        @media (--screen-lt-md) {
            flex-direction: column;
        }
    }

    li {
        inline-size: 100%;
        max-inline-size: 354px;

        @media (--screen-lt-md) {
            max-inline-size: 100%;
        }
    }
}
