.prefooterBackground {
    background: linear-gradient(0deg, var(--composition-background-yellow) 58%, transparent 100%);
    width: 100%;
}

.prefooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--space-12);
        margin-block-start: var(--space-24);
    }
}

.colorSecondary {
    color: var(--display-onlight-secondary);
}
