.viewContainer {
    background-color: var(--white);
    inline-size: var(--view-container-inline-size, 100%);
    block-size: 100%;
    border-radius: inherit;
    position: relative;
    margin-inline: auto;
}

.viewSection {
    display: flex;
    flex-direction: column;

    &.list,
    &.workspace,
    &.activity,
    &.upcoming {
        padding: 0 var(--space-48) 0;
        max-inline-size: var(--container-width-xs, 698px);
        margin-inline: auto;
    }

    &.empty {
        align-items: center;
        color: var(--display-onlight-secondary);
        text-align: center;
    }

    .emptyStateImage {
        max-inline-size: 344px;
        inline-size: 100%;
        block-size: auto;
        aspect-ratio: 344 / 270;
    }

    &.workspace {
        --viewSectionGap: 0;
    }

    > hr {
        &:nth-child(2) {
            margin-block-start: 0;
        }

        &:last-child {
            margin-block-end: 0;
        }
    }
}

:where(.header, .viewSection) + .viewSection:not(.empty, .workspace) {
    margin-block-start: var(--space-32);
}

.textContainer {
    display: flex;
    flex: 1;
    align-items: center;
    gap: var(--space-12);
    inline-size: 100%;
    color: var(--display-onlight-primary);
}

.sectionHeader {
    display: flex;
    align-items: center;
    min-block-size: var(--tube-height-sm);
    color: var(--display-onlight-primary);
}

.boardView {
    position: absolute;

    --column-width: 15.625rem; /* 250px */

    padding: 0 var(--space-48) 0;
    display: flex;
    gap: var(--space-12);

    .viewSection {
        inline-size: var(--column-width);
        gap: var(--space-12);
        margin-block-start: 0;
    }

    &.customPosition {
        position: absolute;
        right: var(--content-x, 0);
        top: var(--content-y, 0);
    }
}

.subheader {
    padding-block-start: var(--space-16);
    border-bottom: 1px solid var(--ui-border);

    .viewDates {
        display: flex;
    }
}

.upcomingViewDate {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    block-size: 44px;

    gap: var(--space-4);
    padding-block-start: var(--space-4);

    > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;

        text-align: center;
        inline-size: 100%;
    }

    .weekday {
        min-inline-size: 26px;
        color: var(--display-onlight-secondary);
    }

    .date {
        color: var(--date-color, var(--display-onlight-primary));
    }

    &.past {
        --date-color: var(--display-onlight-tertiary);
    }

    &.today {
        --date-color: var(--productui-display-color);
    }

    .divider {
        display: block;
        block-size: 2px;
        margin-block-end: -1px;
        background-color: var(--productui-primary-theme-color);
    }
}

.calendarView {
    display: grid;
    grid-template-columns: repeat(7, calc(100% / 7));
    grid-auto-rows: var(--product-ui-calendary-day-height, 92px);

    > * {
        border-top: 1px solid var(--ui-border);
        border-right: 1px solid var(--ui-border);

        &:nth-child(7n) {
            border-right: none;
        }
    }
}

.weeklyCalendarView {
    /* The hour the view should be scrolled to. */
    --hour-anchor: 0;
    --day-count: 4;

    block-size: 100%;
    display: flex;
    flex-direction: column;

    .weeklyCalendarViewDetails {
        overflow: hidden;
        position: relative;
        flex: 1;

        &::after {
            content: "";
            position: absolute;
            border-top: 1px solid var(--product-ui-divider-secondary);
            top: 0;
            left: 0;
            inline-size: var(--space-64);
            block-size: calc(var(--hour-height) / 2);
            background: linear-gradient(white 50%, transparent);
        }

        .weeklyCalendarViewAbsoluteContainer {
            position: absolute;
            inset: 0;
            top: calc(var(--hour-anchor) * var(--hour-height) * -1);

            .weeklyCalendarViewContainer {
                display: grid;
                inline-size: 100%;
                grid-template-columns: 66px repeat(var(--day-count), 1fr);
            }
        }
    }
}
