.overlay {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        backdrop-filter: blur(4px);
        background-color: var(--overlay-background-idle);
    }
}
